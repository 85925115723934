import {
  GET_GROUP_REGIONS_START,
  GET_GROUP_REGIONS_SUCCESS,
  GET_GROUP_REGIONS_FAILURE,
  CREATE_GROUP_REGION_START,
  CREATE_GROUP_REGION_SUCCESS,
  CREATE_GROUP_REGION_FAILURE,
  UPDATE_GROUP_REGION_START,
  UPDATE_GROUP_REGION_SUCCESS,
  UPDATE_GROUP_REGION_FAILURE,
  DELETE_GROUP_REGION_START,
  DELETE_GROUP_REGION_SUCCESS,
  DELETE_GROUP_REGION_FAILURE,
  GET_GROUP_REGION_START,
  GET_GROUP_REGION_SUCCESS,
  GET_GROUP_REGION_FAILURE,
} from "../../actionTypes";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  editSuccess: false,
  createSuccess: false,
  loadingCreate: false,
  deleteSuccess: false,
  totalPages: 1,
};

const regionGroups = (state = initialState, { type, payload }) => {
  switch (type) {
    // GET GROUP REGIONS
    case GET_GROUP_REGIONS_START:
      return { ...state, loading: true };
    case GET_GROUP_REGIONS_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
      };
    case GET_GROUP_REGIONS_FAILURE:
      return { ...state };
    // CREATE GROUP REGION
    case CREATE_GROUP_REGION_START:
      return { ...state, createSuccess: true, loadingCreate: true };
    case CREATE_GROUP_REGION_SUCCESS:
      return { ...state, createSuccess: false, loadingCreate: false };
    case CREATE_GROUP_REGION_FAILURE:
      return { ...state, createSuccess: false, loadingCreate: false };
    // UPDATE GROUP REGION
    case UPDATE_GROUP_REGION_START:
      return { ...state };
    case UPDATE_GROUP_REGION_SUCCESS:
      return { ...state };
    case UPDATE_GROUP_REGION_FAILURE:
      return { ...state };
    // DELETE GROUP REGION
    case DELETE_GROUP_REGION_START:
      return { ...state, editSuccess: true };
    case DELETE_GROUP_REGION_SUCCESS:
      return { ...state, deleteSuccess: false };
    case DELETE_GROUP_REGION_FAILURE:
      return { ...state, deleteSuccess: false };
    // GET GROUP REGION
    case GET_GROUP_REGION_START:
      return { ...state };
    case GET_GROUP_REGION_SUCCESS:
      return { ...state };
    case GET_GROUP_REGION_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default regionGroups;
