import {
  CREATE_MACHINE_MANUFACTURER,
  CREATE_MACHINE_MANUFACTURER_ERROR,
  CREATE_MACHINE_MANUFACTURER_FULFILLED,
  DELETE_MACHINE_MANUFACTURER,
  DELETE_MACHINE_MANUFACTURER_ERROR,
  DELETE_MACHINE_MANUFACTURER_FULFILLED,
  EDIT_MACHINE_MANUFACTURER,
  EDIT_MACHINE_MANUFACTURER_ERROR,
  EDIT_MACHINE_MANUFACTURER_FULFILLED,
  REQUEST_MACHINE_MANUFACTURERS,
  REQUEST_MACHINE_MANUFACTURERS_ERROR,
  REQUEST_MACHINE_MANUFACTURERS_FULFILLED,
  REQUEST_MACHINE_MANUFACTURER_DETAILS,
  REQUEST_MACHINE_MANUFACTURER_DETAILS_ERROR,
  REQUEST_MACHINE_MANUFACTURER_DETAILS_FULFILLED,
} from "redux/actions/machineManufacturers";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  editSuccess: false,
  createSuccess: false,
  loadingCreate: false,
  deleteSuccess: false,
  totalPages: 1,
};

const machineManufacturers = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_MACHINE_MANUFACTURERS:
      return { ...state };
    case REQUEST_MACHINE_MANUFACTURER_DETAILS:
      return { ...state };
    case DELETE_MACHINE_MANUFACTURER:
      return { ...state, editSuccess: false };
    case EDIT_MACHINE_MANUFACTURER:
      return { ...state, deleteSuccess: false };
    case CREATE_MACHINE_MANUFACTURER:
      return { ...state, createSuccess: false, loadingCreate: true };
    // fullfilled
    case REQUEST_MACHINE_MANUFACTURERS_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
      };
    case REQUEST_MACHINE_MANUFACTURER_DETAILS_FULFILLED:
      return { ...state };
    case DELETE_MACHINE_MANUFACTURER_FULFILLED:
      return { ...state, deleteSuccess: false };
    case EDIT_MACHINE_MANUFACTURER_FULFILLED:
      return { ...state, editSuccess: false };
    case CREATE_MACHINE_MANUFACTURER_FULFILLED:
      return { ...state, createSuccess: false, loadingCreate: false };
    // error
    case REQUEST_MACHINE_MANUFACTURERS_ERROR:
      return { ...state };
    case REQUEST_MACHINE_MANUFACTURER_DETAILS_ERROR:
      return { ...state };
    case DELETE_MACHINE_MANUFACTURER_ERROR:
      return { ...state, deleteSuccess: false };
    case EDIT_MACHINE_MANUFACTURER_ERROR:
      return { ...state, editSuccess: false };
    case CREATE_MACHINE_MANUFACTURER_ERROR:
      return { ...state, createSuccess: false, loadingCreate: false };
    default:
      return state;
  }
};

export default machineManufacturers;
