import { CREATE_OFFER, CREATE_OFFER_ERROR, CREATE_OFFER_FULFILLED, DELETE_OFFER, DELETE_OFFER_ERROR, DELETE_OFFER_FULFILLED, EDIT_OFFER, EDIT_OFFER_ERROR, EDIT_OFFER_FULFILLED, REQUEST_OFFERS, REQUEST_OFFERS_ERROR, REQUEST_OFFERS_FULFILLED, REQUEST_OFFER_DETAILS, REQUEST_OFFER_DETAILS_ERROR, REQUEST_OFFER_DETAILS_FULFILLED } from "redux/actions/offers"

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1
}

const offers = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_OFFERS:
      return {...state, }
    case REQUEST_OFFER_DETAILS:
      return {...state, }
    case DELETE_OFFER:
      return {...state, }
    case EDIT_OFFER:
      return {...state, }
    case CREATE_OFFER:
      return {...state, }
    // fullfilled
    case REQUEST_OFFERS_FULFILLED:
      return { ...state, page: payload.page, list: payload.offers, totalPages: payload.pages }
    case REQUEST_OFFER_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_OFFER_FULFILLED:
      return {...state, }
    case EDIT_OFFER_FULFILLED:
      return {...state, }
    case CREATE_OFFER_FULFILLED:
      return {...state, }
      // error
    case REQUEST_OFFERS_ERROR:
      return {...state, }
    case REQUEST_OFFER_DETAILS_ERROR:
      return {...state, }
    case DELETE_OFFER_ERROR:
      return {...state, }
    case EDIT_OFFER_ERROR:
      return {...state, }
    case CREATE_OFFER_ERROR:
      return {...state, }
    default:
      return state
  }
}

export default offers
