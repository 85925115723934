import requestApi from "utility/requestApi";

export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_ACTIVITIES_FULFILLED = "GET_ACTIVITIES_FULFILLED";
export const GET_ACTIVITIES_ERROR = "GET_ACTIVITIES_ERROR";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_FULFILLED = "GET_ACTIVITY_FULFILLED";
export const GET_ACTIVITY_ERROR = "GET_ACTIVITY_ERROR";

export const getActivities = (payload) => async (dispatch) => {
  dispatch({ type: GET_ACTIVITIES });
  try {
    const params = `?${new URLSearchParams(payload.params)}`;
    const { data } = await requestApi.get(`/activities${params}`);
    dispatch({
      type: GET_ACTIVITIES_FULFILLED,
      payload: { ...data, page: payload.page },
    });
  } catch (err) {
    dispatch({ type: GET_ACTIVITIES_ERROR, err });
  }
};

