import { toast } from "react-toastify"
import requestApi from "utility/requestApi"

export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS'
export const REQUEST_LOCATIONS_FULFILLED = 'REQUEST_LOCATIONS_FULFILLED'
export const REQUEST_LOCATIONS_ERROR = 'REQUEST_LOCATIONS_ERROR'

export const REQUEST_LOCATION_DETAILS = 'REQUEST_LOCATION_DETAILS'
export const REQUEST_LOCATION_DETAILS_FULFILLED = 'REQUEST_LOCATION_DETAILS_FULFILLED'
export const REQUEST_LOCATION_DETAILS_ERROR = 'REQUEST_LOCATION_DETAILS_ERROR'

export const DELETE_LOCATION = 'DELETE_LOCATION'
export const DELETE_LOCATION_FULFILLED = 'DELETE_LOCATION_FULFILLED'
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR'

export const EDIT_LOCATION = 'EDIT_LOCATION'
export const EDIT_LOCATION_FULFILLED = 'EDIT_LOCATION_FULFILLED'
export const EDIT_LOCATION_ERROR = 'EDIT_LOCATION_ERROR'

export const CREATE_LOCATION = 'CREATE_LOCATION'
export const CREATE_LOCATION_FULFILLED = 'CREATE_LOCATION_FULFILLED'
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR'

export const REQUEST_ACTIONS = 'REQUEST_ACTIONS'
export const REQUEST_ACTIONS_FULFILLED = 'REQUEST_ACTIONS_FULFILLED'
export const REQUEST_ACTIONS_ERROR = 'REQUEST_ACTIONS_ERROR'

export const REQUEST_LOCATION_BEACONS = 'REQUEST_LOCATION_BEACONS'
export const REQUEST_LOCATION_BEACONS_FULFILLED = 'REQUEST_LOCATION_BEACONS_FULFILLED'
export const REQUEST_LOCATION_BEACONS_ERROR = 'REQUEST_LOCATION_BEACONS_ERROR'

// export const REQUEST_HOURS = 'REQUEST_HOURS'
// export const REQUEST_HOURS_FULFILLED = 'REQUEST_HOURS_FULFILLED'
// export const REQUEST_HOURS_ERROR = 'REQUEST_HOURS_ERROR'



export const getLocations = payload => async dispatch => {
  dispatch({ type: REQUEST_LOCATIONS, payload })
  try {
    const {data} = await requestApi.get(`/locations${payload.params}`);
    dispatch({ type: REQUEST_LOCATIONS_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: REQUEST_LOCATIONS_ERROR})
  }
}

export const getLocationsDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_LOCATION_DETAILS })
  try {
    const {data} = await requestApi.get(`/locations/${payload}`);
    dispatch({ type: REQUEST_LOCATION_DETAILS_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: REQUEST_LOCATION_DETAILS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const getActionsDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_ACTIONS })
  try {
    const {data} = await requestApi.get(`/activities/grouped-by-users?location=${payload.params}`);
    dispatch({ type: REQUEST_ACTIONS_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: REQUEST_ACTIONS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}


export const getLocationBeacons = payload => async dispatch => {
  dispatch({ type: REQUEST_LOCATION_BEACONS })
  try {
    const {data} = await requestApi.get(`/beacons?location=${payload.params}`);
    dispatch({ type: REQUEST_LOCATION_BEACONS_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: REQUEST_LOCATION_BEACONS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

// export const getHoursDetails = payload => async dispatch => {
//   dispatch({ type: REQUEST_HOURS })
//   try {
//     const {data} = await requestApi.get(`/beacons?location=${payload.params}`);
//     dispatch({ type: REQUEST_HOURS_FULFILLED, payload: data })
//   } catch (err) {
//     dispatch({ type: REQUEST_HOURS_ERROR})
//     const {messages} = err.response.data.error
//     messages && toast.error(messages[0])
//     return Promise.reject(err)
//   }
// }

export const deleteLocation = payload => async dispatch => {
  dispatch({ type: DELETE_LOCATION })
  try {
    await requestApi.delete(`/locations/${payload}`);
    dispatch({ type: DELETE_LOCATION_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_LOCATION_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editLocation = payload => async dispatch => {
  dispatch({ type: EDIT_LOCATION })
  try {
    const {data} = await requestApi.patch(`/locations/${payload._id}`, payload);
    dispatch({ type: EDIT_LOCATION_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: EDIT_LOCATION_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createLocation = payload => async dispatch => {
  dispatch({ type: CREATE_LOCATION })
  try {
    const {data} = await requestApi.post(`/locations`, payload);
    dispatch({ type: CREATE_LOCATION_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: CREATE_LOCATION_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}
