import { toast } from "react-toastify"
import requestApi from "utility/requestApi"

export const REQUEST_REWARD_LOGS = 'REQUEST_REWARD_LOGS'
export const REQUEST_REWARD_LOGS_FULFILLED = 'REQUEST_REWARD_LOGS_FULFILLED'
export const REQUEST_REWARD_LOGS_ERROR = 'REQUEST_REWARD_LOGS_ERROR'

export const REQUEST_REWARD_LOG_DETAILS = 'REQUEST_REWARD_LOG_DETAILS'
export const REQUEST_REWARD_LOG_DETAILS_FULFILLED = 'REQUEST_REWARD_LOG_DETAILS_FULFILLED'
export const REQUEST_REWARD_LOG_DETAILS_ERROR = 'REQUEST_REWARD_LOG_DETAILS_ERROR'

export const DELETE_REWARD_LOG = 'DELETE_REWARD_LOG'
export const DELETE_REWARD_LOG_FULFILLED = 'DELETE_REWARD_LOG_FULFILLED'
export const DELETE_REWARD_LOG_ERROR = 'DELETE_REWARD_LOG_ERROR'

export const EDIT_REWARD_LOG = 'EDIT_REWARD_LOG'
export const EDIT_REWARD_LOG_FULFILLED = 'EDIT_REWARD_LOG_FULFILLED'
export const EDIT_REWARD_LOG_ERROR = 'EDIT_REWARD_LOG_ERROR'

export const CREATE_REWARD_LOG = 'CREATE_REWARD_LOG'
export const CREATE_REWARD_LOG_FULFILLED = 'CREATE_REWARD_LOG_FULFILLED'
export const CREATE_REWARD_LOG_ERROR = 'CREATE_REWARD_LOG_ERROR' 

export const getRewards = payload => async dispatch => {
  dispatch({ type: REQUEST_REWARD_LOGS })
  try {
    const {data} = await requestApi.get(`/rewards${payload.params}`);
    dispatch({ type: REQUEST_REWARD_LOGS_FULFILLED, payload: {...data, page: payload.page} })
  } catch (err) {
    dispatch({ type: REQUEST_REWARD_LOGS_ERROR})
  }
}

export const getRewardDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_REWARD_LOG_DETAILS })
  try {
    const {data} = await requestApi.get(`/rewards/${payload}`);
    dispatch({ type: REQUEST_REWARD_LOG_DETAILS_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: REQUEST_REWARD_LOG_DETAILS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const deleteReward = payload => async dispatch => {
  dispatch({ type: DELETE_REWARD_LOG })
  try {
    await requestApi.delete(`/rewards/${payload}`);
    dispatch({ type: DELETE_REWARD_LOG_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_REWARD_LOG_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editReward = payload => async dispatch => {
  dispatch({ type: EDIT_REWARD_LOG })
  try {
    const {data} = await requestApi.patch(`/rewards/${payload._id}`, payload);
    dispatch({ type: EDIT_REWARD_LOG_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: EDIT_REWARD_LOG_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createReward = payload => async dispatch => {
  dispatch({ type: CREATE_REWARD_LOG })
  try {
    const {data} = await requestApi.post(`/rewards`, payload);
    dispatch({ type: CREATE_REWARD_LOG_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: CREATE_REWARD_LOG_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}