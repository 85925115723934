import { GET_PROMOTION_WINNERS, GET_PROMOTION_WINNERS_ERROR, GET_PROMOTION_WINNERS_FULFILLED } from "redux/actions/promotions";

const initialState = {
  winners: {},
  loading: {},
  page: 1,
  totalPages: 1
};

const promotionWinnersReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_PROMOTION_WINNERS:
      return { ...state, loading: { ...state.loading, [payload.drawingId]: true } }
    case GET_PROMOTION_WINNERS_ERROR:
      return { ...state, loading: { ...state.loading, [payload.drawingId]: false } }
    case GET_PROMOTION_WINNERS_FULFILLED:
      return { ...state, loading: { ...state.loading, [payload.drawingId]: false }, winners: { ...state.winners, [payload.drawingId]: payload.winners } }

    default:
      return state;
  }
}

export default promotionWinnersReducer
