import { GET_WINNING_ENTRY, GET_WINNING_ENTRY_ERROR, GET_WINNING_ENTRY_FULFILLED } from "redux/actions/entries";


const initialState = {
  winnerEntries: [],
  loading: false,
  error: null,
  currentEntry: {},

};

const entries = (state = initialState, { type, payload, err }) => {
  switch (type) {
    case GET_WINNING_ENTRY:
      return { ...state, loading: true, error: null };

    case GET_WINNING_ENTRY_FULFILLED:
      payload.entries.docs.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt))
      return {
        ...state,
        winnerEntries: payload.entries.docs,
        loading: false,
        error: null,
      };

    case GET_WINNING_ENTRY_ERROR:
      return { ...state, loading: false, error: err.message };

    default:
      return state;
  }
};

export default entries;