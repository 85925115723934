import {
  CREATE_MACHINE_MODEL,
  CREATE_MACHINE_MODEL_ERROR,
  CREATE_MACHINE_MODEL_FULFILLED,
  DELETE_MACHINE_MODEL,
  DELETE_MACHINE_MODEL_ERROR,
  DELETE_MACHINE_MODEL_FULFILLED,
  EDIT_MACHINE_MODEL,
  EDIT_MACHINE_MODEL_ERROR,
  EDIT_MACHINE_MODEL_FULFILLED,
  REQUEST_MACHINE_MODELS,
  REQUEST_MACHINE_MODELS_ERROR,
  REQUEST_MACHINE_MODELS_FULFILLED,
  REQUEST_MACHINE_MODEL_DETAILS,
  REQUEST_MACHINE_MODEL_DETAILS_ERROR,
  REQUEST_MACHINE_MODEL_DETAILS_FULFILLED,
} from "redux/actions/machineModels";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  editSuccess: false,
  createSuccess: false,
  loadingCreate: false,
  deleteSuccess: false,
  totalPages: 1,
};

const machineModel = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_MACHINE_MODELS:
      return { ...state };
    case REQUEST_MACHINE_MODEL_DETAILS:
      return { ...state };
    case DELETE_MACHINE_MODEL:
      return { ...state, editSuccess: true };
    case EDIT_MACHINE_MODEL:
      return { ...state, deleteSuccess: true };
    case CREATE_MACHINE_MODEL:
      return { ...state, createSuccess: true, loadingCreate: true };
    // fullfilled
    case REQUEST_MACHINE_MODELS_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
      };
    case REQUEST_MACHINE_MODEL_DETAILS_FULFILLED:
      return { ...state };
    case DELETE_MACHINE_MODEL_FULFILLED:
      return { ...state, deleteSuccess: false };
    case EDIT_MACHINE_MODEL_FULFILLED:
      return { ...state, editSuccess: false };
    case CREATE_MACHINE_MODEL_FULFILLED:
      return { ...state, createSuccess: false, loadingCreate: false };
    // error
    case REQUEST_MACHINE_MODELS_ERROR:
      return { ...state };
    case REQUEST_MACHINE_MODEL_DETAILS_ERROR:
      return { ...state };
    case DELETE_MACHINE_MODEL_ERROR:
      return { ...state, deleteSuccess: false };
    case EDIT_MACHINE_MODEL_ERROR:
      return { ...state, editSuccess: false };
    case CREATE_MACHINE_MODEL_ERROR:
      return { ...state, createSuccess: false, loadingCreate: false };
    default:
      return state;
  }
};

export default machineModel;
