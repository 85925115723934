import requestApi from "utility/requestApi";

export const REQUEST_EMPLOYEES = "REQUEST_EMPLOYEES";
export const REQUEST_EMPLOYEES_FULFILLED = "REQUEST_EMPLOYEES_FULFILLED";
export const REQUEST_EMPLOYEES_ERROR = "REQUEST_EMPLOYEES_ERROR";

export const REQUEST_MDM = "REQUEST_MDM";
export const REQUEST_MDM_FULFILLED = "REQUEST_MDM_FULFILLED";
export const REQUEST_MDM_ERROR = "REQUEST_MDM_ERROR";

export const getEmployees = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_EMPLOYEES });
  try {
    const { data } = await requestApi.get(`/employees${payload?.params || ""}`);
    dispatch({
      type: REQUEST_EMPLOYEES_FULFILLED,
      payload: { ...data, page: payload.page },
    });
  } catch (err) {
    dispatch({ type: REQUEST_EMPLOYEES_ERROR });
  }
};

export const getMDMs = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_MDM });
  try {
    const { data } = await requestApi.get(`/users?role=MDM&limit=999`);
    dispatch({
      type: REQUEST_MDM_FULFILLED,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: REQUEST_MDM_ERROR, err });
  }
};
