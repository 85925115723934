import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "users",
    title: "Players",
    type: "item",
    icon: <Icon.Users size={16} />,
  },
  {
    id: "locations",
    title: "Locations",
    type: "item",
    icon: <Icon.MapPin size={16} />,
  },
  {
    id: "regions",
    title: "Areas",
    type: "item",
    icon: <Icon.Map size={16} />,
  },
  {
    id: "surveyresponses",
    title: "Survey Responses",
    type: "item",
    icon: <Icon.BookOpen size={16} />,
  },
  {
    id: "rewardlogs",
    title: "Reward Logs",
    type: "item",
    icon: <Icon.Layers size={16} />,
  },
  {
    id: "configuration",
    title: "Configuration",
    type: "item",
    icon: <Icon.Settings size={16} />,
  },
  {
    id: "offers",
    title: "Offers",
    type: "item",
    icon: <Icon.ShoppingBag size={16} />,
  },
  {
    id: "drawings",
    title: "Drawings",
    type: "item",
    icon: <Icon.PenTool size={16} />,
  },
  {
    id: "notification",
    title: "Notifications",
    type: "item",
    icon: <Icon.Bell size={16} />,
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: <Icon.ShoppingCart size={16} />,
  },
  {
    id: "beacons",
    title: "Beacons",
    type: "item",
    icon: <Icon.Rss size={16} />,
  },
]

export default horizontalMenuConfig
