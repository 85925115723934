import {
  GET_PROMOTION_DASHBOARD_FAILURE,
  GET_PROMOTION_DASHBOARD_START,
  GET_PROMOTION_DASHBOARD_SUCCESS,
} from "redux/actionTypes";
import {
  GET_PROMOTIONS_FULFILLED,
  GET_PROMOTION,
  GET_PROMOTIONS,
  GET_PROMOTION_FULFILLED,
  GET_PROMOTION_DRAWINGS,
  GET_PROMOTION_DRAWINGS_FULFILLED,
  GET_PROMOTION_DRAWINGS_ERROR,
  GET_PROMOTION_GROUP,
  GET_PROMOTION_GROUP_FULFILLED,
  GET_PROMOTION_GROUP_ERROR,
  CREATE_PROMOTION_GROUP,
  CREATE_PROMOTION_GROUP_FULFILLED,
  CREATE_PROMOTION_GROUP_ERROR,
  GET_PROMOTION_ENTRIES,
  GET_PROMOTION_ENTRIES_FULFILLED,
  GET_PROMOTION_ENTRIES_ERROR,
  EDIT_PROMOTION_GROUP_FULFILLED,
} from "redux/actions/promotions";

const initialState = {
  list: [],
  drawings: [],
  groups: [],
  entries: [],
  loading: true,
  page: 1,
  totalPages: 1,
  entriesPagination: {
    limit: 100,
    page: 1,
    pages: 0,
    total: 0,
  },
  dashboard: {
    locations: [],
    totals: [],
    totalsByCompany: [],
  },
  loadingDashboard: true,
};

const promotionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case GET_PROMOTION:
    case GET_PROMOTIONS:
    case GET_PROMOTION_DRAWINGS:
    case GET_PROMOTION_GROUP:
    case GET_PROMOTION_ENTRIES:
    case CREATE_PROMOTION_GROUP:
      return state;

    case GET_PROMOTION_DASHBOARD_START:
      return { ...state, loadingDashboard: true };

    // fullfilled
    case GET_PROMOTION_FULFILLED:
      return state;
    case GET_PROMOTIONS_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false,
      };
    case GET_PROMOTION_DRAWINGS_FULFILLED:
      return { ...state, drawings: payload.docs, loading: false };
    case GET_PROMOTION_GROUP_FULFILLED:
      return { ...state, groups: payload.docs, loading: false };
    case CREATE_PROMOTION_GROUP_FULFILLED:
    case EDIT_PROMOTION_GROUP_FULFILLED:
      return { ...state, loading: false };
    case GET_PROMOTION_ENTRIES_FULFILLED:
      return {
        ...state,
        entries: payload.docs,
        entriesPagination: {
          limit: payload.limit,
          page: payload.page,
          pages: payload.pages,
          total: payload.total,
        },
        loading: false,
      };
    case GET_PROMOTION_DASHBOARD_SUCCESS:
      return { ...state, dashboard: payload, loadingDashboard: false };
    // error
    case GET_PROMOTION_DRAWINGS_ERROR:
    case GET_PROMOTION_GROUP_ERROR:
    case CREATE_PROMOTION_GROUP_ERROR:
    case GET_PROMOTION_ENTRIES_ERROR:
      return { ...state, loading: false };

    case GET_PROMOTION_DASHBOARD_FAILURE:
      return { ...state, loadingDashboard: false };

    default:
      return state;
  }
};

export default promotionsReducer;
