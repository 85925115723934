import { toast } from "react-toastify";
import requestApi from "utility/requestApi";
import { serialize } from "object-to-formdata";

export const REQUEST_MARKETING = "REQUEST_MARKETING";
export const REQUEST_MARKETING_FULFILLED = "REQUEST_MARKETING_FULFILLED";
export const REQUEST_MARKETING_ERROR = "REQUEST_MARKETING_ERROR";

export const REQUEST_MARKETING_DETAILS = "REQUEST_MARKETING_DETAILS";
export const REQUEST_MARKETING_DETAILS_FULFILLED =
  "REQUEST_MARKETING_DETAILS_FULFILLED";
export const REQUEST_MARKETING_DETAILS_ERROR =
  "REQUEST_MARKETING_DETAILS_ERROR";

export const DELETE_MARKETING_RESPONSE = "DELETE_MARKETING_RESPONSE";
export const DELETE_MARKETING_FULFILLED = "DELETE_MARKETING_FULFILLED";
export const DELETE_MARKETING_ERROR = "DELETE_MARKETING_ERROR";

export const EDIT_MARKETING_RESPONSE = "EDIT_MARKETING_RESPONSE";
export const EDIT_MARKETING_FULFILLED = "EDIT_MARKETING_FULFILLED";
export const EDIT_MARKETING_ERROR = "EDIT_MARKETING_ERROR";

export const CREATE_MARKETING_RESPONSE = "CREATE_MARKETING_RESPONSE";
export const CREATE_MARKETING_FULFILLED = "CREATE_MARKETING_FULFILLED";
export const CREATE_MARKETING_ERROR = "REQUEST_MARKETING_MARKETING_ERROR";

export const getMarketing = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_MARKETING });
  try {
    const { data } = await requestApi.get(
      `/marketing?${payload?.params || ""}`
    );

    dispatch({
      type: REQUEST_MARKETING_FULFILLED,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: REQUEST_MARKETING_ERROR, err });
  }
};

export const getMarketingDetails = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_MARKETING_DETAILS });
  try {
    const { data } = await requestApi.get(`/marketing/${payload}`);
    dispatch({
      type: REQUEST_MARKETING_DETAILS_FULFILLED,
      payload: { ...data },
    });
  } catch (err) {
    dispatch({ type: REQUEST_MARKETING_DETAILS_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const deleteMarketing = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_MARKETING_RESPONSE });
  try {
    await requestApi.delete(`/marketing/${payload}`);
    dispatch({ type: DELETE_MARKETING_FULFILLED, payload });
  } catch (err) {
    dispatch({ type: DELETE_MARKETING_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const editMarketing = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_MARKETING_RESPONSE });
  try {
    const { data } = await requestApi.patch(
      `/marketing/${payload._id}`,
      serialize(payload)
    );
    dispatch({ type: EDIT_MARKETING_FULFILLED, payload: data });
  } catch (err) {
    dispatch({ type: EDIT_MARKETING_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const createMarketing = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_MARKETING_RESPONSE });
  try {
    const { data } = await requestApi.post(`/marketing`, serialize(payload));
    dispatch({ type: CREATE_MARKETING_FULFILLED, payload: data });
  } catch (err) {
    dispatch({ type: CREATE_MARKETING_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};
