import {
  CREATE_GAMESET,
  CREATE_GAMESET_ERROR,
  CREATE_GAMESET_FULFILLED,
  DELETE_GAMESET,
  DELETE_GAMESET_ERROR,
  DELETE_GAMESET_FULFILLED,
  EDIT_GAMESET,
  EDIT_GAMESET_ERROR,
  EDIT_GAMESET_FULFILLED,
  REQUEST_GAMESETS,
  REQUEST_GAMESETS_ERROR,
  REQUEST_GAMESETS_FULFILLED,
  REQUEST_GAMESET_DETAILS,
  REQUEST_GAMESET_DETAILS_ERROR,
  REQUEST_GAMESET_DETAILS_FULFILLED,
} from "redux/actions/gamesets";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  editSuccess: false,
  createSuccess: false,
  loadingCreate: false,
  deleteSuccess: false,
  totalPages: 1,
};

const gamesets = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_GAMESETS:
      return { ...state, loading: true };
    case REQUEST_GAMESET_DETAILS:
      return { ...state };
    case DELETE_GAMESET:
      return { ...state, editSuccess: true };
    case EDIT_GAMESET:
      return { ...state, deleteSuccess: true };
    case CREATE_GAMESET:
      return { ...state, createSuccess: true, loadingCreate: true };
    // fullfilled
    case REQUEST_GAMESETS_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false,
      };
    case REQUEST_GAMESET_DETAILS_FULFILLED:
      return { ...state };
    case DELETE_GAMESET_FULFILLED:
      return { ...state, deleteSuccess: false };
    case EDIT_GAMESET_FULFILLED:
      return { ...state, editSuccess: false };
    case CREATE_GAMESET_FULFILLED:
      return { ...state, createSuccess: false, loadingCreate: false };
    // error
    case REQUEST_GAMESETS_ERROR:
      return { ...state };
    case REQUEST_GAMESET_DETAILS_ERROR:
      return { ...state };
    case DELETE_GAMESET_ERROR:
      return { ...state, deleteSuccess: false };
    case EDIT_GAMESET_ERROR:
      return { ...state, editSuccess: false };
    case CREATE_GAMESET_ERROR:
      return { ...state, createSuccess: false, loadingCreate: false };
    default:
      return state;
  }
};

export default gamesets;
