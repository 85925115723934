import { CREATE_CONFIGURATION, CREATE_CONFIGURATION_ERROR, CREATE_CONFIGURATION_FULFILLED, DELETE_CONFIGURATION, DELETE_CONFIGURATION_ERROR, DELETE_CONFIGURATION_FULFILLED, EDIT_CONFIGURATION, EDIT_CONFIGURATION_ERROR, EDIT_CONFIGURATION_FULFILLED, REQUEST_CONFIGURATIONS, REQUEST_CONFIGURATIONS_ERROR, REQUEST_CONFIGURATIONS_FULFILLED, REQUEST_CONFIGURATION_DETAILS, REQUEST_CONFIGURATION_DETAILS_ERROR, REQUEST_CONFIGURATION_DETAILS_FULFILLED } from "redux/actions/configurations"

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1
}

const configurations = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_CONFIGURATIONS:
      return {...state, }
    case REQUEST_CONFIGURATION_DETAILS:
      return {...state, }
    case DELETE_CONFIGURATION:
      return {...state, }
    case EDIT_CONFIGURATION:
      return {...state, }
    case CREATE_CONFIGURATION:
      return {...state, }
    // fullfilled
    case REQUEST_CONFIGURATIONS_FULFILLED:
      const list = []
      Object.keys(payload.drawingTemplates).map( key => list.push(payload.drawingTemplates[key]))
      return { ...state, list}
    case REQUEST_CONFIGURATION_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_CONFIGURATION_FULFILLED:
      return {...state, }
    case EDIT_CONFIGURATION_FULFILLED:
      return {...state, }
    case CREATE_CONFIGURATION_FULFILLED:
      return {...state, }
      // error
    case REQUEST_CONFIGURATIONS_ERROR:
      return {...state, }
    case REQUEST_CONFIGURATION_DETAILS_ERROR:
      return {...state, }
    case DELETE_CONFIGURATION_ERROR:
      return {...state, }
    case EDIT_CONFIGURATION_ERROR:
      return {...state, }
    case CREATE_CONFIGURATION_ERROR:
      return {...state, }
    default:
      return state
  }
}

export default configurations