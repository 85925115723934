import { toast } from "react-toastify";
import requestApi from "utility/requestApi";
import { fetchMe } from "../auth";

export const REQUEST_ROLES = "REQUEST_ROLES";
export const REQUEST_ROLES_FULFILLED = "REQUEST_ROLES_FULFILLED";
export const REQUEST_ROLES_ERROR = "REQUEST_ROLES_ERROR";

export const REQUEST_ROLE_DETAILS = "REQUEST_ROLE_DETAILS";
export const REQUEST_ROLE_DETAILS_FULFILLED = "REQUEST_ROLE_DETAILS_FULFILLED";
export const REQUEST_ROLE_DETAILS_ERROR = "REQUEST_ROLE_DETAILS_ERROR";

export const DELETE_ROLE_RESPONSE = "DELETE_ROLE_RESPONSE";
export const DELETE_ROLE_FULFILLED = "DELETE_ROLE_FULFILLED";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

export const EDIT_ROLE_RESPONSE = "EDIT_ROLE_RESPONSE";
export const EDIT_ROLE_FULFILLED = "EDIT_ROLE_FULFILLED";
export const EDIT_ROLE_ERROR = "EDIT_ROLE_ERROR";

export const CREATE_ROLE_RESPONSE = "CREATE_ROLE_RESPONSE";
export const CREATE_ROLE_FULFILLED = "CREATE_ROLE_FULFILLED";
export const CREATE_ROLE_ERROR = "REQUEST_ROLE_ROLE_ERROR";

export const PERMISSON_CATEGORIES = {
  NULL: "NULL",
  REGIONS_LOCATIONS: "REGIONS & LOCATIONS",
  PLAYERS: "PLAYERS",
  REWARDS_CAMPAIGNS: "REWARDS & CAMPAIGNS",
  ADMINISTRATION: "ADMINISTRATION",
  SUPPORT: "SUPPORT",
};

const categorizedPermissions = {
  // dashboard: {
  //   category: PERMISSON_CATEGORIES.NULL,
  //   label: "Dashboard",
  //   permission: "dashboard",
  // },
  locations: {
    category: PERMISSON_CATEGORIES.REGIONS_LOCATIONS,
    label: "Locations",
    permission: "locations",
  },
  gameSets: {
    category: PERMISSON_CATEGORIES.REGIONS_LOCATIONS,
    label: "Gameset",
    permission: "gameSets",
  },
  players: {
    category: PERMISSON_CATEGORIES.PLAYERS,
    label: "Players",
    permission: "players",
  },
  roles: {
    category: PERMISSON_CATEGORIES.PLAYERS,
    label: "Roles",
    permission: "roles",
  },
  checkins: {
    category: PERMISSON_CATEGORIES.REWARDS_CAMPAIGNS,
    label: "Checkins",
    permission: "promotions",
  },
  promotions: {
    category: PERMISSON_CATEGORIES.REWARDS_CAMPAIGNS,
    label: "Promotions",
    permission: "promotions",
  },
  marketing: {
    category: PERMISSON_CATEGORIES.REWARDS_CAMPAIGNS,
    label: "Marketing",
    permission: "marketing",
  },
  notifications: {
    category: PERMISSON_CATEGORIES.REWARDS_CAMPAIGNS,
    label: "Notifications",
    permission: "notifications",
  },
  calendar: {
    category: PERMISSON_CATEGORIES.REWARDS_CAMPAIGNS,
    label: "Calendar",
    permission: "calendar",
  },
  tags: {
    category: PERMISSON_CATEGORIES.ADMINISTRATION,
    label: "Tags",
    permission: "tags",
  },
  configurations: {
    category: PERMISSON_CATEGORIES.ADMINISTRATION,
    label: "Configurations",
    permission: "configurations",
  },
  drawings: {
    category: PERMISSON_CATEGORIES.SUPPORT,
    label: "Drawings",
    permission: "drawings",
  },
  products: {
    category: PERMISSON_CATEGORIES.SUPPORT,
    label: "Products",
    permission: "products",
  },
  beacons: {
    category: PERMISSON_CATEGORIES.SUPPORT,
    label: "Beacons",
    permission: "beacons",
  },
};

function categorizePermissions(roles) {
  const result = {};
  roles.forEach(() => {
    Object.keys(categorizedPermissions).forEach((permission) => {
      if (!result[permission] && categorizedPermissions[permission]) {
        result[permission] = {
          ...categorizedPermissions[permission],
        };
      }
    });
  });
  return result;
}

export const getRoles = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_ROLES });
  try {
    const { data } = await requestApi.get(
      `/permissions?${payload?.params || ""}`
    );

    dispatch({
      type: REQUEST_ROLES_FULFILLED,
      payload: {
        ...data,
        categorizedPermissions: categorizePermissions(data.docs),
      },
    });
  } catch (err) {
    dispatch({ type: REQUEST_ROLES_ERROR, err });
  }
};

export const getRoleDetails = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_ROLE_DETAILS });
  try {
    const { data } = await requestApi.get(`/permissions/${payload}`);
    dispatch({ type: REQUEST_ROLE_DETAILS_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: REQUEST_ROLE_DETAILS_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const deleteRole = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_ROLE_RESPONSE });
  try {
    await requestApi.delete(`/permissions/${payload}`);
    dispatch({ type: DELETE_ROLE_FULFILLED, payload });
  } catch (err) {
    dispatch({ type: DELETE_ROLE_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const editRole = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_ROLE_RESPONSE });
  try {
    const { data } = await requestApi.patch(
      `/permissions/${payload._id}`,
      payload
    );
    dispatch({ type: EDIT_ROLE_FULFILLED, payload: { ...data } });
    dispatch(getRoles());
    dispatch(fetchMe());
  } catch (err) {
    dispatch({ type: EDIT_ROLE_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const createRole = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_ROLE_RESPONSE });
  try {
    const { data } = await requestApi.post(`/permissions`, payload);
    dispatch({ type: CREATE_ROLE_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: CREATE_ROLE_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};
