import { toast } from "react-toastify";
import requestApi from "utility/requestApi";

export const REQUEST_REGIONS = "REQUEST_REGIONS";
export const REQUEST_REGIONS_FULFILLED = "REQUEST_REGIONS_FULFILLED";
export const REQUEST_REGIONS_ERROR = "REQUEST_REGIONS_ERROR";

export const REQUEST_REGION_DETAILS = "REQUEST_REGION_DETAILS";
export const REQUEST_REGION_DETAILS_FULFILLED =
  "REQUEST_REGION_DETAILS_FULFILLED";
export const REQUEST_REGION_DETAILS_ERROR = "REQUEST_REGION_DETAILS_ERROR";

export const DELETE_REGION = "DELETE_REGION";
export const DELETE_REGION_FULFILLED = "DELETE_REGION_FULFILLED";
export const DELETE_REGION_ERROR = "DELETE_REGION_ERROR";

export const EDIT_REGION = "EDIT_REGION";
export const EDIT_REGION_FULFILLED = "EDIT_REGION_FULFILLED";
export const EDIT_REGION_ERROR = "EDIT_REGION_ERROR";

export const CREATE_REGION = "CREATE_REGION";
export const CREATE_REGION_FULFILLED = "CREATE_REGION_FULFILLED";
export const CREATE_REGION_ERROR = "CREATE_REGION_ERROR";

export const getRegions = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_REGIONS });
  try {
    const { data } = await requestApi.get(`/regions${payload.params}`);
    dispatch({
      type: REQUEST_REGIONS_FULFILLED,
      payload: { ...data, page: payload.page },
    });
  } catch (err) {
    dispatch({ type: REQUEST_REGIONS_ERROR });
  }
};

export const getRegionDetails = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_REGION_DETAILS });
  try {
    const { data } = await requestApi.get(`/regions/${payload}`);
    dispatch({ type: REQUEST_REGION_DETAILS_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: REQUEST_REGION_DETAILS_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const deleteRegion = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_REGION });
  try {
    await requestApi.delete(`/regions/${payload}`);
    dispatch({ type: DELETE_REGION_FULFILLED, payload });
  } catch (err) {
    dispatch({ type: DELETE_REGION_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const editRegion = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_REGION });
  try {
    const { data } = await requestApi.patch(`/regions/${payload._id}`, payload);
    dispatch({ type: EDIT_REGION_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: EDIT_REGION_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const createRegion = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_REGION });
  try {
    const { data } = await requestApi.post(`/regions`, payload);
    dispatch({ type: CREATE_REGION_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: CREATE_REGION_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};
