import { toast } from 'react-toastify'
import requestApi from 'utility/requestApi'
import { serialize } from 'object-to-formdata'

export const GET_PRIZES = 'GET_PRIZES'
export const GET_PRIZES_FULFILLED = 'GET_PRIZES_FULFILLED'
export const GET_PRIZES_ERROR = 'GET_PRIZES_ERROR'

export const CREATE_PRIZE = 'CREATE_PRIZE'
export const CREATE_PRIZE_FULFILLED = 'CREATE_PRIZE_FULFILLED'
export const CREATE_PRIZE_ERROR = 'CREATE_PRIZE_ERROR'

export const EDIT_PRIZE = 'EDIT_PRIZE'
export const EDIT_PRIZE_FULFILLED = 'EDIT_PRIZE_FULFILLED'
export const EDIT_PRIZE_ERROR = 'EDIT_PRIZE_ERROR'

export const DELETE_PRIZE = 'DELETE_PRIZE'
export const DELETE_PRIZE_FULFILLED = 'DELETE_PRIZE_FULFILLED'
export const DELETE_PRIZE_ERROR = 'DELETE_PRIZE_ERROR'

export const GET_PRIZE = 'GET_PRIZE'
export const GET_PRIZE_FULFILLED = 'GET_PRIZE_FULFILLED'
export const GET_PRIZE_ERROR = 'GET_PRIZE_ERROR'

export const getPrizes = (payload) => async (dispatch) => {
  dispatch({ type: GET_PRIZES })
  try {
    const params = `?${new URLSearchParams(payload.params)}`
    const { data } = await requestApi.get(`/prizes${params}`)
    dispatch({
      type: GET_PRIZES_FULFILLED,
      payload: { ...data, page: payload.page }
    })
  } catch (err) {
    dispatch({ type: GET_PRIZES_ERROR, err })
  }
}

export const createPrize = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_PRIZE })
  try {
    const { data } = await requestApi.post(`/prizes`, serialize(payload))
    dispatch({ type: CREATE_PRIZE_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: CREATE_PRIZE_ERROR })
    const { messages } = err.response.data.error
    if (messages) {
      toast.error(messages[0])
    }
    return Promise.reject(err)
  }
}

export const editPrize = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_PRIZE })
  try {
    const { data } = await requestApi.patch(
      `/prizes/${payload._id}`,
      serialize(payload)
    )
    dispatch({ type: EDIT_PRIZE_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: EDIT_PRIZE_ERROR })
    const { messages } = err.response.data.error
    if (messages) {
      toast.error(messages[0])
    }
    return Promise.reject(err)
  }
}

export const deletePrize = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_PRIZE })
  try {
    await requestApi.delete(`/prizes/${payload}`)
    dispatch({ type: DELETE_PRIZE_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_PRIZE_ERROR })
    const { messages } = err.response.data.error
    if (messages) {
      toast.error(messages[0])
    }
    return Promise.reject(err)
  }
}

export const getPrize = (payload) => async (dispatch) => {
  dispatch({ type: GET_PRIZE })
  try {
    const { data } = await requestApi.get(`/prizes/${payload}`)
    dispatch({ type: GET_PRIZE_FULFILLED, payload: data })
    return data
  } catch (err) {
    dispatch({ type: GET_PRIZE_ERROR })
  }
}
