import { CREATE_SURVEY_RESPONSE, CREATE_SURVEY_ERROR, CREATE_SURVEY_FULFILLED, DELETE_SURVEY_RESPONSE, DELETE_SURVEY_ERROR, DELETE_SURVEY_FULFILLED, EDIT_SURVEY_RESPONSE, EDIT_SURVEY_ERROR, EDIT_SURVEY_FULFILLED, REQUEST_SURVEYS, REQUEST_SURVEYS_ERROR, REQUEST_SURVEYS_FULFILLED, REQUEST_SURVEY_DETAILS, REQUEST_SURVEY_DETAILS_ERROR, REQUEST_SURVEY_DETAILS_FULFILLED } from "redux/actions/surveys"

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1
}

const surveys = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_SURVEYS:
      return {...state, }
    case REQUEST_SURVEY_DETAILS:
      return {...state, }
    case DELETE_SURVEY_RESPONSE:
      return {...state, }
    case EDIT_SURVEY_RESPONSE:
      return {...state, }
    case CREATE_SURVEY_RESPONSE:
      return {...state, }
    // fullfilled
    case REQUEST_SURVEYS_FULFILLED:
      return { ...state, page: payload.page, list: payload.surveyResponses, totalPages: payload.pages }
    case REQUEST_SURVEY_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_SURVEY_FULFILLED:
      return {...state, }
    case EDIT_SURVEY_FULFILLED:
      return {...state, }
    case CREATE_SURVEY_FULFILLED:
      return {...state, }
      // error
    case REQUEST_SURVEYS_ERROR:
      return {...state, }
    case REQUEST_SURVEY_DETAILS_ERROR:
      return {...state, }
    case DELETE_SURVEY_ERROR:
      return {...state, }
    case EDIT_SURVEY_ERROR:
      return {...state, }
    case CREATE_SURVEY_ERROR:
      return {...state, }
    default:
      return state
  }
}

export default surveys
