import { toast } from "react-toastify"
import requestApi from "utility/requestApi"

export const REQUEST_USERS = 'REQUEST_USERS'
export const REQUEST_USERS_FULFILLED = 'REQUEST_USERS_FULFILLED'
export const REQUEST_USERS_ERROR = 'REQUEST_USERS_ERROR'

export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS'
export const REQUEST_USER_DETAILS_FULFILLED = 'REQUEST_USER_DETAILS_FULFILLED'
export const REQUEST_USER_DETAILS_ERROR = 'REQUEST_USER_DETAILS_ERROR'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_FULFILLED = 'EDIT_USER_FULFILLED'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const getUsers = payload => async dispatch => {
  dispatch({ type: REQUEST_USERS })
  try {
    const {data} = await requestApi.get(`/users${payload.params}`);
    dispatch({ type: REQUEST_USERS_FULFILLED, payload: {...data, page: payload.page} })
  } catch (err) {
    dispatch({ type: REQUEST_USERS_ERROR})
  }
}

export const getUserDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_USER_DETAILS })
  try {
    const {data} = await requestApi.get(`/users/${payload}`);
    dispatch({ type: REQUEST_USER_DETAILS_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: REQUEST_USER_DETAILS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const deleteUser = payload => async dispatch => {
  dispatch({ type: DELETE_USER })
  try {
    await requestApi.delete(`/users/${payload}`);
    dispatch({ type: DELETE_USER_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_USER_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editUser = payload => async dispatch => {
  dispatch({ type: EDIT_USER })
  try {
    const {data} = await requestApi.patch(`/users/${payload._id}`, payload);
    dispatch({ type: EDIT_USER_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: EDIT_USER_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createUser = payload => async dispatch => {
  dispatch({ type: CREATE_USER })
  try {
    const {data} = await requestApi.post(`/users`, payload);
    dispatch({ type: CREATE_USER_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: CREATE_USER_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}