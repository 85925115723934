import { toast } from "react-toastify";
import requestApi from "utility/requestApi";

export const REQUEST_TAGS = "REQUEST_TAGS";
export const REQUEST_TAGS_FULLFILLED = "REQUEST_TAGS_FULLFILLED";
export const REQUEST_TAGS_ERROR = "REQUEST_REGIONS_ERROR";

export const DELETE_TAGS = "DELETE_TAGS";
export const DELETE_TAGS_FULLFILLED = "DELETE_TAGS_FULLFILLED";
export const DELETE_TAGS_ERROR = "DELETE_TAGS_ERROR";

export const EDIT_TAGS = "EDIT_TAGS";
export const EDIT_TAGS_FULLFILLED = "EDIT_TAG_FULLFILLED";
export const EDIT_TAGS_ERROR = "EDIT_TAGS_ERROR";

export const CREATE_TAGS = "CREATE_TAGS";
export const CREATE_TAGS_FULFILLED = "CREATE_TAGS_FULFILLED";
export const CREATE_TAGS_ERROR = "CREATE_TAGS_ERROR";

export const getTags = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_TAGS });
  try {
    const { data } = await requestApi.get(`/tags${payload.params}`);
    dispatch({
      type: REQUEST_TAGS_FULLFILLED,
      payload: { ...data, page: payload.page },
    });
  } catch (error) {
    dispatch({ type: REQUEST_TAGS_ERROR });
  }
};

export const deleteTag = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_TAGS });
  try {
    const { data } = await requestApi.delete(`/tags/${payload}`);
    dispatch({ type: DELETE_TAGS_FULLFILLED, payload: { ...data } });
  } catch (error) {
    dispatch({ type: DELETE_TAGS_ERROR });
    const { messages } = error.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(error);
  }
};

export const createTags = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_TAGS });
  try {
    const { data } = await requestApi.post(`/tags`, payload);
    dispatch({ type: CREATE_TAGS_FULFILLED, payload: { ...data } });
  } catch (error) {
    dispatch({ type: CREATE_TAGS_ERROR });
    const { messages } = error.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(error);
  }
};
