import {
  GET_PROMOTION_DEVICES,
  GET_PROMOTION_DEVICES_ERROR,
  GET_PROMOTION_DEVICES_FULFILLED,
} from "redux/actions/promotionDevices";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  totalPages: 1,
  categorizedPermissions: {},
};

const promotionDevices = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case GET_PROMOTION_DEVICES:
      return { ...state, loading: true };
    // fullfilled
    case GET_PROMOTION_DEVICES_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false,
      };
    // error
    case GET_PROMOTION_DEVICES_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default promotionDevices;
