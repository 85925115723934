import {
  REQUEST_REWARD_LOGS,
  REQUEST_REWARD_LOGS_FULFILLED,
  REQUEST_REWARD_LOGS_ERROR,
  REQUEST_REWARD_LOG_DETAILS,
  REQUEST_REWARD_LOG_DETAILS_FULFILLED,
  REQUEST_REWARD_LOG_DETAILS_ERROR,
  DELETE_REWARD_LOG,
  DELETE_REWARD_LOG_FULFILLED,
  DELETE_REWARD_LOG_ERROR,
  EDIT_REWARD_LOG,
  EDIT_REWARD_LOG_FULFILLED,
  EDIT_REWARD_LOG_ERROR,
  CREATE_REWARD_LOG,
  CREATE_REWARD_LOG_FULFILLED,
  CREATE_REWARD_LOG_ERROR,
} from "redux/actions/rewards"

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1
}

const rewards = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_REWARD_LOGS:
      return {...state, }
    case REQUEST_REWARD_LOG_DETAILS:
      return {...state, }
    case DELETE_REWARD_LOG:
      return {...state, }
    case EDIT_REWARD_LOG:
      return {...state, }
    case CREATE_REWARD_LOG:
      return {...state, }
    // fullfilled
    case REQUEST_REWARD_LOGS_FULFILLED:
      return { ...state, page: payload.page, list: payload.rewards, totalPages: payload.pages }
    case REQUEST_REWARD_LOG_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_REWARD_LOG_FULFILLED:
      return {...state, }
    case EDIT_REWARD_LOG_FULFILLED:
      return {...state, }
    case CREATE_REWARD_LOG_FULFILLED:
      return {...state, }
      // error
    case REQUEST_REWARD_LOGS_ERROR:
      return {...state, }
    case REQUEST_REWARD_LOG_DETAILS_ERROR:
      return {...state, }
    case DELETE_REWARD_LOG_ERROR:
      return {...state, }
    case EDIT_REWARD_LOG_ERROR:
      return {...state, }
    case CREATE_REWARD_LOG_ERROR:
      return {...state, }
    default:
      return state
  }
}

export default rewards
