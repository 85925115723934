import axios from "axios";
import cookies from "./cookieHandler";

export const apiUrl = process.env.REACT_APP_API_URL;

const requestApi = axios.create({ baseURL: `${apiUrl}` });

export const refreshTokenFailCallback = [];

requestApi.interceptors.request.use(
  (config) => {
    const token = cookies.getCookie("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

requestApi.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        refreshTokenFailCallback.forEach((callback) => callback(error));
      }
    }
    return Promise.reject(error);
  }
);

// requestApi.interceptors.response.use(response => response,
//   function (error) {
//     // const originalRequest = error.config;
//     // if( error.response )
//       // if (error.response.status === 401 && !originalRequest._retry) {

//       //   originalRequest._retry = true;
//       //   return requestApi.post('/RefreshToken', {
//       //       refreshToken: cookies.getCookie('refreshToken')
//       //     }).then(res => {
//       //       if (res.status === 200) {
//       //         // 1) put token to LocalStorage
//       //         cookies.setCookie('refreshToken', res.data.refresh_token)
//       //         cookies.setCookie('token', res.data.id_token)

//       //         // 2) Change Authorization header
//       //         requestApi.defaults.headers.common['Authorization'] = `Bearer ${res.data.id_token}`;

//       //         // 3) return originalRequest object with Axios.
//       //         return requestApi(originalRequest);
//       //       }
//       //     }).catch((err) => {
//       //       refreshTokenFailCallback.forEach(callback => callback(err));
//       //       return originalRequest;
//       //     })
//       // }
//     // return Error object with Promise
//     return Promise.reject(error);
//   });

export default requestApi;
