import { toast } from 'react-toastify'
import requestApi from 'utility/requestApi'

export const REQUEST_GAMESETS = 'REQUEST_GAMESETS'
export const REQUEST_GAMESETS_FULFILLED = 'REQUEST_GAMESETS_FULFILLED'
export const REQUEST_GAMESETS_ERROR = 'REQUEST_GAMESETS_ERROR'

export const REQUEST_GAMESET_DETAILS = 'REQUEST_GAMESET_DETAILS'
export const REQUEST_GAMESET_DETAILS_FULFILLED = 'REQUEST_GAMESET_DETAILS_FULFILLED'
export const REQUEST_GAMESET_DETAILS_ERROR = 'REQUEST_GAMESET_DETAILS_ERROR'

export const DELETE_GAMESET = 'DELETE_GAMESET'
export const DELETE_GAMESET_FULFILLED = 'DELETE_GAMESET_FULFILLED'
export const DELETE_GAMESET_ERROR = 'DELETE_GAMESET_ERROR'

export const EDIT_GAMESET = 'EDIT_GAMESET'
export const EDIT_GAMESET_FULFILLED = 'EDIT_GAMESET_FULFILLED'
export const EDIT_GAMESET_ERROR = 'EDIT_GAMESET_ERROR'

export const CREATE_GAMESET = 'CREATE_GAMESET'
export const CREATE_GAMESET_FULFILLED = 'CREATE_GAMESET_FULFILLED'
export const CREATE_GAMESET_ERROR = 'CREATE_GAMESET_ERROR'

export const getGamesets = payload => async dispatch => {
  dispatch({ type: REQUEST_GAMESETS })
  try {
    const { data } = await requestApi.get(`/game-sets?${payload?.params || ''}`);
    dispatch({ type: REQUEST_GAMESETS_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: REQUEST_GAMESETS_ERROR })
  }
}

export const getGamesetDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_GAMESET_DETAILS })
  try {
    const { data } = await requestApi.get(`/game-sets/${payload}`);
    dispatch({ type: REQUEST_GAMESET_DETAILS_FULFILLED, payload: { ...data } })
  } catch (err) {
    dispatch({ type: REQUEST_GAMESET_DETAILS_ERROR })
  }
}

export const deleteGameset = payload => async dispatch => {
  dispatch({ type: DELETE_GAMESET })
  try {
    await requestApi.delete(`/game-sets/${payload}`);
    dispatch({ type: DELETE_GAMESET_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_GAMESET_ERROR })
    const { messages } = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editGameset = payload => async dispatch => {
  dispatch({ type: EDIT_GAMESET })
  try {
    const { data } = await requestApi.patch(`/game-sets/${payload._id}`, payload);
    dispatch({ type: EDIT_GAMESET_FULFILLED, payload: { ...data } })
  } catch (err) {
    dispatch({ type: EDIT_GAMESET_ERROR })
    const { messages } = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createGameset = payload => async dispatch => {
  dispatch({ type: CREATE_GAMESET })
  try {
    const { data } = await requestApi.post(`/game-sets`, payload);
    dispatch({ type: CREATE_GAMESET_FULFILLED, payload: { ...data } })
  } catch (err) {
    dispatch({ type: CREATE_GAMESET_ERROR })
    const { messages } = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}
