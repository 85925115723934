import { CREATE_REGION, CREATE_REGION_ERROR, CREATE_REGION_FULFILLED, DELETE_REGION, DELETE_REGION_ERROR, DELETE_REGION_FULFILLED, EDIT_REGION, EDIT_REGION_ERROR, EDIT_REGION_FULFILLED, REQUEST_REGIONS, REQUEST_REGIONS_ERROR, REQUEST_REGIONS_FULFILLED, REQUEST_REGION_DETAILS, REQUEST_REGION_DETAILS_ERROR, REQUEST_REGION_DETAILS_FULFILLED } from "redux/actions/regions";

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1
}

const regions = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_REGIONS:
      return {...state, }
    case REQUEST_REGION_DETAILS:
      return {...state, }
    case DELETE_REGION:
      return {...state, }
    case EDIT_REGION:
      return {...state, }
    case CREATE_REGION:
      return {...state, }
    // fullfilled
    case REQUEST_REGIONS_FULFILLED:
      return { ...state, page: payload.page, list: payload.regions, totalPages: payload.pages }
    case REQUEST_REGION_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_REGION_FULFILLED:
      return {...state, }
    case EDIT_REGION_FULFILLED:
      return {...state, }
    case CREATE_REGION_FULFILLED:
      return {...state, }
      // error
    case REQUEST_REGIONS_ERROR:
      return {...state, }
    case REQUEST_REGION_DETAILS_ERROR:
      return {...state, }
    case DELETE_REGION_ERROR:
      return {...state, }
    case EDIT_REGION_ERROR:
      return {...state, }
    case CREATE_REGION_ERROR:
      return {...state, }
    default:
      return state
  }
}

export default regions
