import {
  REQUEST_TAGS,
  REQUEST_TAGS_FULLFILLED,
  REQUEST_TAGS_ERROR,
  DELETE_TAGS,
  DELETE_TAGS_FULLFILLED,
  DELETE_TAGS_ERROR,
  EDIT_TAGS,
  EDIT_TAGS_FULLFILLED,
  EDIT_TAGS_ERROR,
  CREATE_TAGS,
  CREATE_TAGS_FULFILLED,
  CREATE_TAGS_ERROR,
} from "redux/actions/tags";

const initialState = {
  list: [],
  surveys: {},
  loading: false,
  page: 1,
  totalPages: 1,
};

const tags = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_TAGS:
      return { ...state, loading: true };
    case DELETE_TAGS:
      return { ...state, loading: true };
    case EDIT_TAGS:
      return { ...state, loading: true };
    case CREATE_TAGS:
      return { ...state, loading: true };
    // fullfilled
    case REQUEST_TAGS_FULLFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false
      };
    case DELETE_TAGS_FULLFILLED:
      return { ...state, loading: false };
    case EDIT_TAGS_FULLFILLED:
      return { ...state, loading: false };
    case CREATE_TAGS_FULFILLED:
      return { ...state, loading: false };
    // error
    case REQUEST_TAGS_ERROR:
      return { ...state, loading: false };
    case DELETE_TAGS_ERROR:
      return { ...state, loading: false };
    case EDIT_TAGS_ERROR:
      return { ...state, loading: false };
    case CREATE_TAGS_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default tags;
