import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "item",
  //   icon: <Icon.Grid size={16} />,
  //   navLink: "/",
  // },
  {
    id: "regions-header",
    groupTitle: "REGIONS & LOCATIONS",
    type: "groupHeader",
    permissions: ["locations", "gameSets"],
  },
  {
    id: "locations",
    title: "Locations",
    type: "item",
    icon: <Icon.MapPin size={16} />,
    navLink: "/locations",
    permissions: ["locations"],
  },
  {
    id: "gameset",
    title: "Gameset",
    type: "item",
    icon: <Icon.Share2 size={16} />,
    navLink: "/gamesets",
    permissions: ["gameSets"],
  },
  //  {
  //   id: "regions",
  //    title: "Regions",
  //    type: "item",
  //    icon: <Icon.Map size={16} />,
  //    navLink: "/regions",
  //  },
  {
    id: "customer-header",
    groupTitle: "PLAYERS",
    type: "groupHeader",
    permissions: ["players", "roles"],
  },
  {
    id: "users",
    title: "Players",
    type: "item",
    icon: <Icon.Users size={16} />,
    navLink: "/users",
    permissions: ["players"],
  },
  {
    id: "roles",
    title: "Roles",
    type: "item",
    icon: <Icon.Eye size={16} />,
    navLink: "/roles",
    permissions: ["roles"],
  },
  //  {
  //    id: "surveyresponses",
  //    title: "Survey Responses",
  //    type: "item",
  //    icon: <Icon.BookOpen size={16} />,
  //    navLink: "/surveys",
  //  },
  {
    id: "rewards-header",
    groupTitle: "REWARDS & CAMPAIGNS",
    type: "groupHeader",
    permissions: ["calendar", "promotions", "notifications"],
  },
  {
    id: "prizes",
    title: "Prizes",
    type: "item",
    icon: <Icon.Target size={16} />,
    navLink: "/prizes",
    permissions: ["promotions"],
  },
  {
    id: "checkins",
    title: "Check-Ins",
    type: "item",
    icon: <Icon.ShoppingBag size={16} />,
    navLink: "/check-ins",
    permissions: ["promotions"],
  },
  {
    id: "promotions",
    title: "Promotions",
    type: "item",
    icon: <Icon.ShoppingBag size={16} />,
    navLink: "/promotions",
    permissions: ["promotions"],
  },
  {
    id: "marketing",
    title: "Marketing",
    type: "item",
    icon: <Icon.ShoppingBag size={16} />,
    navLink: "/marketing",
    permissions: ["marketing"],
  },
  //  {
  //    id: "offers",
  //    title: "Offers",
  //    type: "item",
  //    icon: <Icon.ShoppingBag size={16} />,
  //    navLink: "/offers",
  //  },
  {
    id: "notification",
    title: "Notifications",
    type: "item",
    icon: <Icon.Bell size={16} />,
    navLink: "/notifications",
    permissions: ["notifications"],
  },
  //  {
  //    id: "rewardlogs",
  //    title: "Reward Logs",
  //    type: "item",
  //    icon: <Icon.Layers size={16} />,
  //    navLink: "/rewards",
  //  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    navLink: "/calendar",
    permissions: ["calendar"],
  },
  // {
  //   id: "analytics-header",
  //   title: "ANALYTICS & REPORTING",
  //   type: "header",
  // },
  // {
  //   id: "store-performance",
  //   title: "Store Performance",
  //   type: "item",
  //   icon: <Icon.PieChart size={16} />,
  //   navLink: "/store-performance"
  // },
  // {
  //   id: "player-performance",
  //   title: "Player Performance",
  //   type: "item",
  //   icon: <Icon.Activity size={16} />,
  //   navLink: "/player-performance"
  // },
  // {
  //   id: "flagged-events",
  //   title: "Flagged Events",
  //   type: "item",
  //   icon: <Icon.Flag size={16} />,
  //   navLink: "/flagged-events"
  // },
  {
    id: "administration-header",
    groupTitle: "ADMINISTRATION",
    type: "groupHeader",
    permissions: ["tags", "configurations"],
  },
  {
    id: "tags",
    title: "Tags",
    type: "item",
    icon: <Icon.Tag size={16} />,
    navLink: "/tags",
    permissions: ["tags"],
  },
  {
    id: "configurations",
    title: "Configurations",
    type: "item",
    icon: <Icon.Settings size={16} />,
    navLink: "/configurations",
    permissions: ["configurations"],
  },
  {
    id: "support-header",
    groupTitle: "SUPPORT",
    type: "groupHeader",
    permissions: ["drawings", "products", "beacons"],
  },
  {
    id: "drawings",
    title: "Drawings",
    type: "item",
    icon: <Icon.PenTool size={16} />,
    navLink: "/drawings",
    permissions: ["drawings"],
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: <Icon.ShoppingCart size={16} />,
    navLink: "/products",
    permissions: ["products"],
  },
  {
    id: "beacons",
    title: "Beacons",
    type: "item",
    icon: <Icon.Rss size={16} />,
    navLink: "/beacons",
    permissions: ["beacons"],
  },
];

export default navigationConfig;
