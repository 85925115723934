import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_ERROR,
  GET_ACTIVITIES_FULFILLED,
} from "redux/actions/activities";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  totalPages: 1,
  categorizedPermissions: {},
};

const activities = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case GET_ACTIVITIES:
      return { ...state, loading: true };
    // fullfilled
    case GET_ACTIVITIES_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false,
      };
    // error
    case GET_ACTIVITIES_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default activities;
