import {
  GET_PRIZES,
  GET_PRIZES_ERROR,
  GET_PRIZES_FULFILLED,
  CREATE_PRIZE,
  CREATE_PRIZE_ERROR,
  CREATE_PRIZE_FULFILLED,
  DELETE_PRIZE,
  DELETE_PRIZE_ERROR,
  DELETE_PRIZE_FULFILLED,
  EDIT_PRIZE,
  EDIT_PRIZE_ERROR,
  EDIT_PRIZE_FULFILLED,
  GET_PRIZE,
  GET_PRIZE_FULFILLED,
  GET_PRIZE_ERROR,
} from "redux/actions/prizes";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  totalPages: 1,
};

const prizesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case GET_PRIZES:
      return { ...state, loading: true };
    case GET_PRIZE:
      return { ...state };
    case DELETE_PRIZE:
      return { ...state };
    case EDIT_PRIZE:
      return { ...state };
    case CREATE_PRIZE:
      return { ...state };
    // fullfilled
    case GET_PRIZES_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false,
      };
    case GET_PRIZE_FULFILLED:
      return { ...state };
    case DELETE_PRIZE_FULFILLED:
      return { ...state };
    case EDIT_PRIZE_FULFILLED:
      return { ...state };
    case CREATE_PRIZE_FULFILLED:
      return { ...state };
    // error
    case GET_PRIZES_ERROR:
      return { ...state, loading: false };
    case GET_PRIZE_ERROR:
      return { ...state };
    case DELETE_PRIZE_ERROR:
      return { ...state };
    case EDIT_PRIZE_ERROR:
      return { ...state };
    case CREATE_PRIZE_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default prizesReducer;
