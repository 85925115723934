import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_FULFILLED,
  CREATE_NOTIFICATION_ERROR,
} from "redux/actions/notifications";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  totalPages: 1,
};

const notificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_NOTIFICATION:
      return { ...state, loading: true };
    case CREATE_NOTIFICATION_FULFILLED:
      return {
        ...state,
        list: payload.notifications,
        page: payload.page,
        totalPages: payload.pages,
      };
    case CREATE_NOTIFICATION_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default notificationsReducer;
