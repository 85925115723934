import { toast } from 'react-toastify'
import requestApi from 'utility/requestApi'

export const REQUEST_BEACONS = 'REQUEST_BEACONS'
export const REQUEST_BEACONS_FULFILLED = 'REQUEST_BEACONS_FULFILLED'
export const REQUEST_BEACONS_ERROR = 'REQUEST_BEACONS_ERROR'

export const REQUEST_BEACON_DETAILS = 'REQUEST_BEACON_DETAILS'
export const REQUEST_BEACON_DETAILS_FULFILLED = 'REQUEST_BEACON_DETAILS_FULFILLED'
export const REQUEST_BEACON_DETAILS_ERROR = 'REQUEST_BEACON_DETAILS_ERROR'

export const DELETE_BEACON = 'DELETE_BEACON'
export const DELETE_BEACON_FULFILLED = 'DELETE_BEACON_FULFILLED'
export const DELETE_BEACON_ERROR = 'DELETE_BEACON_ERROR'

export const EDIT_BEACON = 'EDIT_BEACON'
export const EDIT_BEACON_FULFILLED = 'EDIT_BEACON_FULFILLED'
export const EDIT_BEACON_ERROR = 'EDIT_BEACON_ERROR'

export const CREATE_BEACON = 'CREATE_BEACON'
export const CREATE_BEACON_FULFILLED = 'CREATE_BEACON_FULFILLED'
export const CREATE_BEACON_ERROR = 'CREATE_BEACON_ERROR'

export const getBeacons = payload => async dispatch => {
  dispatch({ type: REQUEST_BEACONS })
  try {
    const { data } = await requestApi.get(`/beacons${payload.params}`);
    dispatch({ type: REQUEST_BEACONS_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: REQUEST_BEACONS_ERROR })
  }
}

export const getBeaconDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_BEACON_DETAILS })
  try {
    const { data } = await requestApi.get(`/beacons/${payload}`);
    dispatch({ type: REQUEST_BEACON_DETAILS_FULFILLED, payload: { ...data } })
  } catch (err) {
    dispatch({ type: REQUEST_BEACON_DETAILS_ERROR })
  }
}

export const deleteBeacon = payload => async dispatch => {
  dispatch({ type: DELETE_BEACON })
  try {
    await requestApi.delete(`/beacons/${payload}`);
    dispatch({ type: DELETE_BEACON_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_BEACON_ERROR })
    const { messages } = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editBeacon = payload => async dispatch => {
  dispatch({ type: EDIT_BEACON })
  try {
    const { data } = await requestApi.patch(`/beacons/${payload._id}`, payload);
    dispatch({ type: EDIT_BEACON_FULFILLED, payload: { ...data } })
  } catch (err) {
    dispatch({ type: EDIT_BEACON_ERROR })
    const { messages } = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createBeacon = payload => async dispatch => {
  dispatch({ type: CREATE_BEACON })
  try {
    const { data } = await requestApi.post(`/beacons`, payload);
    dispatch({ type: CREATE_BEACON_FULFILLED, payload: { ...data } })
  } catch (err) {
    dispatch({ type: CREATE_BEACON_ERROR })
    const { messages } = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}
