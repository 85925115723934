import {
  GET_CHECKINS,
  GET_CHECKINS_ERROR,
  GET_CHECKINS_FULFILLED,
} from "redux/actions/checkins";
import {
  CREATE_MARKETING_RESPONSE,
  CREATE_MARKETING_ERROR,
  CREATE_MARKETING_FULFILLED,
  DELETE_MARKETING_RESPONSE,
  DELETE_MARKETING_ERROR,
  DELETE_MARKETING_FULFILLED,
  EDIT_MARKETING_RESPONSE,
  EDIT_MARKETING_ERROR,
  EDIT_MARKETING_FULFILLED,
  REQUEST_MARKETING_DETAILS,
  REQUEST_MARKETING_DETAILS_ERROR,
  REQUEST_MARKETING_DETAILS_FULFILLED,
} from "redux/actions/marketing";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  totalPages: 1,
  categorizedPermissions: {},
};

const CHECKINS = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case GET_CHECKINS:
      return { ...state, loading: true };
    case REQUEST_MARKETING_DETAILS:
      return { ...state };
    case DELETE_MARKETING_RESPONSE:
      return { ...state };
    case EDIT_MARKETING_RESPONSE:
      return { ...state };
    case CREATE_MARKETING_RESPONSE:
      return { ...state };
    // fullfilled
    case GET_CHECKINS_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false,
      };
    case REQUEST_MARKETING_DETAILS_FULFILLED:
      return { ...state };
    case DELETE_MARKETING_FULFILLED:
      return { ...state };
    case EDIT_MARKETING_FULFILLED:
      return { ...state };
    case CREATE_MARKETING_FULFILLED:
      return { ...state };
    // error
    case GET_CHECKINS_ERROR:
      return { ...state, loading: false };
    case REQUEST_MARKETING_DETAILS_ERROR:
      return { ...state };
    case DELETE_MARKETING_ERROR:
      return { ...state };
    case EDIT_MARKETING_ERROR:
      return { ...state };
    case CREATE_MARKETING_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default CHECKINS;
