import { toast } from "react-toastify";
import requestApi from "utility/requestApi";
import { serialize } from "object-to-formdata";

export const GET_CHECKINS = "GET_CHECKINS";
export const GET_CHECKINS_FULFILLED = "GET_CHECKINS_FULFILLED";
export const GET_CHECKINS_ERROR = "GET_CHECKINS_ERROR";

export const CREATE_CHECKIN = "CREATE_CHECKIN";
export const CREATE_CHECKIN_FULFILLED = "CREATE_CHECKIN_FULFILLED";
export const CREATE_CHECKIN_ERROR = "CREATE_CHECKIN_ERROR";

export const EDIT_CHECKIN = "EDIT_CHECKIN";
export const EDIT_CHECKIN_FULFILLED = "EDIT_CHECKIN_FULFILLED";
export const EDIT_CHECKIN_ERROR = "EDIT_CHECKIN_ERROR";

export const DELETE_CHECKIN = "DELETE_CHECKIN";
export const DELETE_CHECKIN_FULFILLED = "DELETE_CHECKIN_FULFILLED";
export const DELETE_CHECKIN_ERROR = "DELETE_CHECKIN_ERROR";

export const GET_CHECKIN = "GET_CHECKIN";
export const GET_CHECKIN_FULFILLED = "GET_CHECKIN_FULFILLED";
export const GET_CHECKIN_ERROR = "GET_CHECKIN_ERROR";

export const getCheckins = (payload) => async (dispatch) => {
  dispatch({ type: GET_CHECKINS });
  try {
    const params = `?${new URLSearchParams(payload.params)}`;
    const { data } = await requestApi.get(`/promotions${params}`);
    dispatch({
      type: GET_CHECKINS_FULFILLED,
      payload: { ...data, page: payload.page },
    });
  } catch (err) {
    dispatch({ type: GET_CHECKINS_ERROR });
  }
};

export const createCheckin = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_CHECKIN });
  try {
    const { data } = await requestApi.post(`/promotions`, serialize(payload));
    dispatch({ type: CREATE_CHECKIN_FULFILLED, payload: data });
  } catch (err) {
    dispatch({ type: CREATE_CHECKIN_ERROR });
    const { messages } = err.response.data.error;
    if (messages) {
      toast.error(messages[0]);
    }
    return Promise.reject(err);
  }
};

export const editPromotion = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_CHECKIN });
  try {
    const { data } = await requestApi.patch(
      `/promotions/${payload._id}`,
      serialize(payload)
    );
    dispatch({ type: EDIT_CHECKIN_FULFILLED, payload: data });
  } catch (err) {
    dispatch({ type: EDIT_CHECKIN_ERROR });
    const { messages } = err.response.data.error;
    if (messages) {
      toast.error(messages[0]);
    }
    return Promise.reject(err);
  }
};

export const deletePromotion = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_CHECKIN });
  try {
    await requestApi.delete(`/promotions/${payload}`);
    dispatch({ type: DELETE_CHECKIN_FULFILLED, payload });
  } catch (err) {
    dispatch({ type: DELETE_CHECKIN_ERROR });
    const { messages } = err.response.data.error;
    if (messages) {
      toast.error(messages[0]);
    }
    return Promise.reject(err);
  }
};

export const getPromotion = (payload) => async (dispatch) => {
  dispatch({ type: GET_CHECKIN });
  try {
    const { data } = await requestApi.get(`/promotions/${payload}`);
    const { drawings, startDate, endDate } = data;
    if (drawings) {
      data.drawings = drawings.map(
        (drawing) => drawing.drawingDate?.split("T")?.[0]
      );
    }
    if (startDate) {
      data.startDate = data.startDate?.split("T")?.[0];
    }
    if (endDate) {
      data.endDate = data.endDate?.split("T")?.[0];
    }
    dispatch({ type: GET_CHECKIN_FULFILLED, payload: data });
    return data;
  } catch (err) {
    dispatch({ type: GET_CHECKIN_ERROR });
  }
};
