import {
  CREATE_ROLE_RESPONSE,
  CREATE_ROLE_ERROR,
  CREATE_ROLE_FULFILLED,
  DELETE_ROLE_RESPONSE,
  DELETE_ROLE_ERROR,
  DELETE_ROLE_FULFILLED,
  EDIT_ROLE_RESPONSE,
  EDIT_ROLE_ERROR,
  EDIT_ROLE_FULFILLED,
  REQUEST_ROLES,
  REQUEST_ROLES_ERROR,
  REQUEST_ROLES_FULFILLED,
  REQUEST_ROLE_DETAILS,
  REQUEST_ROLE_DETAILS_ERROR,
  REQUEST_ROLE_DETAILS_FULFILLED,
} from "redux/actions/roles";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  totalPages: 1,
  categorizedPermissions: {},
};

const roles = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_ROLES:
      return { ...state };
    case REQUEST_ROLE_DETAILS:
      return { ...state };
    case DELETE_ROLE_RESPONSE:
      return { ...state };
    case EDIT_ROLE_RESPONSE:
      return { ...state };
    case CREATE_ROLE_RESPONSE:
      return { ...state };
    // fullfilled
    case REQUEST_ROLES_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        categorizedPermissions: payload.categorizedPermissions,
      };
    case REQUEST_ROLE_DETAILS_FULFILLED:
      return { ...state };
    case DELETE_ROLE_FULFILLED:
      return { ...state };
    case EDIT_ROLE_FULFILLED:
      return { ...state };
    case CREATE_ROLE_FULFILLED:
      return { ...state };
    // error
    case REQUEST_ROLES_ERROR:
      return { ...state };
    case REQUEST_ROLE_DETAILS_ERROR:
      return { ...state };
    case DELETE_ROLE_ERROR:
      return { ...state };
    case EDIT_ROLE_ERROR:
      return { ...state };
    case CREATE_ROLE_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default roles;
