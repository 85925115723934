import {
  CREATE_LOCATION,
  CREATE_LOCATION_ERROR,
  CREATE_LOCATION_FULFILLED,
  DELETE_LOCATION,
  DELETE_LOCATION_ERROR,
  DELETE_LOCATION_FULFILLED,
  EDIT_LOCATION,
  EDIT_LOCATION_ERROR,
  EDIT_LOCATION_FULFILLED,
  REQUEST_LOCATIONS,
  REQUEST_LOCATIONS_ERROR,
  REQUEST_LOCATIONS_FULFILLED,
  REQUEST_LOCATION_DETAILS,
  REQUEST_LOCATION_DETAILS_ERROR,
  REQUEST_LOCATION_DETAILS_FULFILLED,
  REQUEST_ACTIONS,
  REQUEST_ACTIONS_ERROR,
  REQUEST_ACTIONS_FULFILLED,
  REQUEST_LOCATION_BEACONS,
  REQUEST_LOCATION_BEACONS_ERROR,
  REQUEST_LOCATION_BEACONS_FULFILLED,
} from "redux/actions/locations";

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1,
  activity: [],
  loadingActivity: true,
  beacons: [],
  loadingBeacons: false,
};

const locations = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_LOCATIONS:
      return { ...state };
    case REQUEST_LOCATION_DETAILS:
      return { ...state };
    case DELETE_LOCATION:
      return { ...state };
    case EDIT_LOCATION:
      return { ...state };
    case CREATE_LOCATION:
      return { ...state };
    case REQUEST_ACTIONS:
      return { ...state, loadingActivity: true };
    case REQUEST_LOCATION_BEACONS:
      return { ...state, loadingBeacons: true };
    // fullfilled
    case REQUEST_LOCATIONS_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.locations,
        totalPages: payload.pages,
      };
    case REQUEST_LOCATION_DETAILS_FULFILLED:
      return { ...state };
    case DELETE_LOCATION_FULFILLED:
      return { ...state };
    case EDIT_LOCATION_FULFILLED:
      return { ...state };
    case CREATE_LOCATION_FULFILLED:
      return { ...state };
    case REQUEST_ACTIONS_FULFILLED:
      return { ...state, activity: payload, loadingActivity: false };

    case REQUEST_LOCATION_BEACONS_FULFILLED:
      return { ...state, beacons: payload, loadingBeacons: false };
    // error
    case REQUEST_LOCATIONS_ERROR:
      return { ...state };
    case REQUEST_LOCATION_DETAILS_ERROR:
      return { ...state };
    case DELETE_LOCATION_ERROR:
      return { ...state };
    case EDIT_LOCATION_ERROR:
      return { ...state };
    case CREATE_LOCATION_ERROR:
      return { ...state };
    case REQUEST_ACTIONS_ERROR:
      return { ...state, loadingActivity: false };
    case REQUEST_LOCATION_BEACONS_ERROR:
      return { ...state, loadingBeacons: false };
    default:
      return state;
  }
};

export default locations;
