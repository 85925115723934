import { toast } from "react-toastify";
import requestApi from "utility/requestApi";
import { serialize } from "object-to-formdata";

export const REQUEST_GAMES = "REQUEST_GAMES";
export const REQUEST_GAMES_FULFILLED = "REQUEST_GAMES_FULFILLED";
export const REQUEST_GAMES_ERROR = "REQUEST_GAMES_ERROR";

export const REQUEST_GAME_DETAILS = "REQUEST_GAME_DETAILS";
export const REQUEST_GAME_DETAILS_FULFILLED = "REQUEST_GAME_DETAILS_FULFILLED";
export const REQUEST_GAME_DETAILS_ERROR = "REQUEST_GAME_DETAILS_ERROR";

export const DELETE_GAME = "DELETE_GAME";
export const DELETE_GAME_FULFILLED = "DELETE_GAME_FULFILLED";
export const DELETE_GAME_ERROR = "DELETE_GAME_ERROR";

export const EDIT_GAME = "EDIT_GAME";
export const EDIT_GAME_FULFILLED = "EDIT_GAME_FULFILLED";
export const EDIT_GAME_ERROR = "EDIT_GAME_ERROR";

export const CREATE_GAME = "CREATE_GAME";
export const CREATE_GAME_FULFILLED = "CREATE_GAME_FULFILLED";
export const CREATE_GAME_ERROR = "CREATE_GAME_ERROR";

export const getGames = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_GAMES });
  try {
    const { data } = await requestApi.get(`/games?${payload?.params || ""}`);
    dispatch({ type: REQUEST_GAMES_FULFILLED, payload: data });
  } catch (err) {
    dispatch({ type: REQUEST_GAMES_ERROR });
  }
};

export const getGameDetails = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_GAME_DETAILS });
  try {
    const { data } = await requestApi.get(`/games/${payload}`);
    dispatch({ type: REQUEST_GAME_DETAILS_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: REQUEST_GAME_DETAILS_ERROR });
  }
};

export const deleteGame = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_GAME });
  try {
    await requestApi.delete(`/games/${payload}`);
    dispatch({ type: DELETE_GAME_FULFILLED, payload });
  } catch (err) {
    dispatch({ type: DELETE_GAME_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const editGame = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_GAME });
  try {
    const { data } = await requestApi.patch(
      `/games/${payload._id}`,
      serialize(payload)
    );
    dispatch({ type: EDIT_GAME_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: EDIT_GAME_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const createGame = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_GAME });
  try {
    const { data } = await requestApi.post(`/games`, serialize(payload));
    dispatch({ type: CREATE_GAME_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: CREATE_GAME_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};
