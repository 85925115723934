import { CREATE_BEACON, CREATE_BEACON_ERROR, CREATE_BEACON_FULFILLED, DELETE_BEACON, DELETE_BEACON_ERROR, DELETE_BEACON_FULFILLED, EDIT_BEACON, EDIT_BEACON_ERROR, EDIT_BEACON_FULFILLED, REQUEST_BEACONS, REQUEST_BEACONS_ERROR, REQUEST_BEACONS_FULFILLED, REQUEST_BEACON_DETAILS, REQUEST_BEACON_DETAILS_ERROR, REQUEST_BEACON_DETAILS_FULFILLED } from "redux/actions/beacons"

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  editSuccess: false, 
  createSuccess: false, 
  deleteSuccess: false,
  totalPages: 1
}

const beacons = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_BEACONS:
      return {...state, }
    case REQUEST_BEACON_DETAILS:
      return {...state, }
    case DELETE_BEACON:
      return {...state, editSuccess: true }
    case EDIT_BEACON:
      return {...state, deleteSuccess: true }
    case CREATE_BEACON:
      return {...state, createSuccess: true}
    // fullfilled
    case REQUEST_BEACONS_FULFILLED:
      return { ...state, page: payload.page, list: payload.beacons, totalPages: payload.pages }
    case REQUEST_BEACON_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_BEACON_FULFILLED:
      return {...state, deleteSuccess: false}
    case EDIT_BEACON_FULFILLED:
      return {...state, editSuccess: false}
    case CREATE_BEACON_FULFILLED:
      return {...state, createSuccess: false}
      // error
    case REQUEST_BEACONS_ERROR:
      return {...state, }
    case REQUEST_BEACON_DETAILS_ERROR:
      return {...state, }
    case DELETE_BEACON_ERROR:
      return {...state, deleteSuccess: false}
    case EDIT_BEACON_ERROR:
      return {...state, editSuccess: false}
    case CREATE_BEACON_ERROR:
      return {...state, createSuccess: false}
    default:
      return state
  }
}

export default beacons