import { toast } from "react-toastify"
import requestApi from "utility/requestApi"

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const REQUEST_PRODUCTS_FULFILLED = 'REQUEST_PRODUCTS_FULFILLED'
export const REQUEST_PRODUCTS_ERROR = 'REQUEST_PRODUCTS_ERROR'

export const REQUEST_PRODUCT_DETAILS = 'REQUEST_PRODUCT_DETAILS'
export const REQUEST_PRODUCT_DETAILS_FULFILLED = 'REQUEST_PRODUCT_DETAILS_FULFILLED'
export const REQUEST_PRODUCT_DETAILS_ERROR = 'REQUEST_PRODUCT_DETAILS_ERROR'

export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_FULFILLED = 'DELETE_PRODUCT_FULFILLED'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'

export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const EDIT_PRODUCT_FULFILLED = 'EDIT_PRODUCT_FULFILLED'
export const EDIT_PRODUCT_ERROR = 'EDIT_PRODUCT_ERROR'

export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_FULFILLED = 'CREATE_PRODUCT_FULFILLED'
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR' 

export const getProducts = payload => async dispatch => {
  dispatch({ type: REQUEST_PRODUCTS })
  try {
    const {data} = await requestApi.get(`/products${payload.params}`);
    dispatch({ type: REQUEST_PRODUCTS_FULFILLED, payload: {...data, page: payload.page} })
  } catch (err) {
    dispatch({ type: REQUEST_PRODUCTS_ERROR})
  }
}

export const getProductDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_PRODUCT_DETAILS })
  try {
    const {data} = await requestApi.get(`/products/${payload}`);
    dispatch({ type: REQUEST_PRODUCT_DETAILS_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: REQUEST_PRODUCT_DETAILS_ERROR})
  }
}

export const deleteProduct = payload => async dispatch => {
  dispatch({ type: DELETE_PRODUCT })
  try {
    await requestApi.delete(`/products/${payload}`);
    dispatch({ type: DELETE_PRODUCT_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_PRODUCT_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editProduct = payload => async dispatch => {
  dispatch({ type: EDIT_PRODUCT })
  try {
    const {data} = await requestApi.patch(`/products/${payload._id}`, payload);
    dispatch({ type: EDIT_PRODUCT_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: EDIT_PRODUCT_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createProduct = payload => async dispatch => {
  dispatch({ type: CREATE_PRODUCT })
  try {
    const {data} = await requestApi.post(`/products`, payload);
    dispatch({ type: CREATE_PRODUCT_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: CREATE_PRODUCT_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}