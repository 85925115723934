import {
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_FULFILLED,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_FULFILLED,
  EDIT_USER,
  EDIT_USER_ERROR,
  EDIT_USER_FULFILLED,
  REQUEST_USERS,
  REQUEST_USERS_ERROR,
  REQUEST_USERS_FULFILLED,
  REQUEST_USER_DETAILS,
  REQUEST_USER_DETAILS_ERROR,
  REQUEST_USER_DETAILS_FULFILLED,
} from "redux/actions/users";

const initialState = {
  list: [],
  users: {},
  loading: true,
  page: 1,
  totalPages: 1,
  loadingDetails: true,
};

const users = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_USERS:
      return { ...state, loading: true };
    case REQUEST_USER_DETAILS:
      return { ...state, loadingDetails: true };
    case DELETE_USER:
      return { ...state };
    case EDIT_USER:
      return { ...state };
    case CREATE_USER:
      return { ...state };
    // fullfilled
    case REQUEST_USERS_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.users,
        totalPages: payload.pages,
        loading: false,
      };
    case REQUEST_USER_DETAILS_FULFILLED:
      return { ...state, userDetails: payload, loadingDetails: false };
    case DELETE_USER_FULFILLED:
      return { ...state, loading: false };
    case EDIT_USER_FULFILLED:
      return { ...state };
    case CREATE_USER_FULFILLED:
      return { ...state };
    // error
    case REQUEST_USERS_ERROR:
      return { ...state, loading: false };
    case REQUEST_USER_DETAILS_ERROR:
      return { ...state };
    case DELETE_USER_ERROR:
      return { ...state };
    case EDIT_USER_ERROR:
      return { ...state };
    case CREATE_USER_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default users;
