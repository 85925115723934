import requestApi from "utility/requestApi";

export const GET_WINNING_ENTRY = "GET_WINNING_ENTRY";
export const GET_WINNING_ENTRY_FULFILLED = "GET_WINNING_ENTRY_FULFILLED";
export const GET_WINNING_ENTRY_ERROR = "GET_WINNING_ENTRY_ERROR";

export const getWinningEntry = (entryId) => async (dispatch) => {
  dispatch({ type: GET_WINNING_ENTRY });
  try {
    const { data } = await requestApi.get(`/promotion-entries/${entryId}/?limit=1000`);
    dispatch({
      type: GET_WINNING_ENTRY_FULFILLED,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: GET_WINNING_ENTRY_ERROR, err });
  }
};