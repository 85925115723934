import { toast } from "react-toastify";
import requestApi from "utility/requestApi";

export const REQUEST_OFFERS = "REQUEST_OFFERS";
export const REQUEST_OFFERS_FULFILLED = "REQUEST_OFFERS_FULFILLED";
export const REQUEST_OFFERS_ERROR = "REQUEST_OFFERS_ERROR";

export const REQUEST_OFFER_DETAILS = "REQUEST_OFFER_DETAILS";
export const REQUEST_OFFER_DETAILS_FULFILLED =
  "REQUEST_OFFER_DETAILS_FULFILLED";
export const REQUEST_OFFER_DETAILS_ERROR = "REQUEST_OFFER_DETAILS_ERROR";

export const DELETE_OFFER = "DELETE_OFFER";
export const DELETE_OFFER_FULFILLED = "DELETE_OFFER_FULFILLED";
export const DELETE_OFFER_ERROR = "DELETE_OFFER_ERROR";

export const EDIT_OFFER = "EDIT_OFFER";
export const EDIT_OFFER_FULFILLED = "EDIT_OFFER_FULFILLED";
export const EDIT_OFFER_ERROR = "EDIT_OFFER_ERROR";

export const CREATE_OFFER = "CREATE_OFFER";
export const CREATE_OFFER_FULFILLED = "CREATE_OFFER_FULFILLED";
export const CREATE_OFFER_ERROR = "CREATE_OFFER_ERROR";

export const getOffers = (payload) => async (dispatch) => {
  dispatch({
    type: REQUEST_OFFERS,
  });
  try {
    const { data } = await requestApi.get(`/offers${payload.params}`);

    dispatch({
      type: REQUEST_OFFERS_FULFILLED,
      payload: {
        ...data,
        page: payload.page,
      },
    });
  } catch (err) {
    dispatch({
      type: REQUEST_OFFERS_ERROR,
    });
  }
};

export const getOfferDetails = (payload) => async (dispatch) => {
  dispatch({
    type: REQUEST_OFFER_DETAILS,
  });
  try {
    const { data } = await requestApi.get(`/offers/${payload}`);
    dispatch({
      type: REQUEST_OFFER_DETAILS_FULFILLED,
      payload: {
        ...data,
      },
    });
  } catch (err) {
    dispatch({
      type: REQUEST_OFFER_DETAILS_ERROR,
    });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const deleteOffer = (payload) => async (dispatch) => {
  dispatch({
    type: DELETE_OFFER,
  });
  try {
    await requestApi.delete(`/offers/${payload}`);
    dispatch({
      type: DELETE_OFFER_FULFILLED,
      payload,
    });
  } catch (err) {
    dispatch({
      type: DELETE_OFFER_ERROR,
    });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const editOffer = (payload) => async (dispatch) => {
  dispatch({
    type: EDIT_OFFER,
  });
  try {
    const { data } = await requestApi.patch(`/offers/${payload._id}`, payload);
    dispatch({
      type: EDIT_OFFER_FULFILLED,
      payload: {
        ...data,
      },
    });
  } catch (err) {
    dispatch({
      type: EDIT_OFFER_ERROR,
    });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const createOffer = (payload) => async (dispatch) => {
  dispatch({
    type: CREATE_OFFER,
  });
  try {
    const params =
      payload.queryKey && _getOfferParams(payload.queryKey, payload.locationId);
    const { data } = await requestApi.post(`/offers`, { ...payload, params });
    dispatch({
      type: CREATE_OFFER_FULFILLED,
      payload: {
        ...data,
      },
    });
  } catch (err) {
    dispatch({
      type: CREATE_OFFER_ERROR,
    });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

const _getOfferParams = (query, locationId) => {
  switch (query) {
    case "last-checkin":
      return [
        {
          name: "locationId",
          value: locationId,
        },
        {
          name: "xDays",
          value: 60,
        },
        {
          name: "yDays",
          value: 10,
        },
      ];

    case "all-users":
      return [
        {
          // OPTIONAL
          name: "location",
          value: locationId,
        },
      ];

    case "average-checkins":
      return [
        {
          name: "xDays",
          value: 60,
        },
        {
          name: "yDays",
          value: 10,
        },
        {
          name: "checkinNumberThreshold",
          value: 10,
        },
        {
          name: "gamingDurationMinutesOffset",
          value: 7,
        },
      ];

    case "gaming-time":
      return [
        {
          name: "xDays",
          value: 60,
        },
        {
          name: "yDays",
          value: 10,
        },
        {
          name: "gamingDurationMinutesThreshold",
          value: 600,
        },
        {
          name: "gamingDurationMinutesOffset",
          value: 7,
        },
      ];
    default:
      break;
  }
};
