import { toast } from "react-toastify";
import requestApi from "utility/requestApi";

export const REQUEST_MACHINE_MODELS = "REQUEST_MACHINE_MODELS";
export const REQUEST_MACHINE_MODELS_FULFILLED =
  "REQUEST_MACHINE_MODELS_FULFILLED";
export const REQUEST_MACHINE_MODELS_ERROR = "REQUEST_MACHINE_MODELS_ERROR";

export const REQUEST_MACHINE_MODEL_DETAILS = "REQUEST_MACHINE_MODEL_DETAILS";
export const REQUEST_MACHINE_MODEL_DETAILS_FULFILLED =
  "REQUEST_MACHINE_MODEL_DETAILS_FULFILLED";
export const REQUEST_MACHINE_MODEL_DETAILS_ERROR =
  "REQUEST_MACHINE_MODEL_DETAILS_ERROR";

export const DELETE_MACHINE_MODEL = "DELETE_MACHINE_MODEL";
export const DELETE_MACHINE_MODEL_FULFILLED = "DELETE_MACHINE_MODEL_FULFILLED";
export const DELETE_MACHINE_MODEL_ERROR = "DELETE_MACHINE_MODEL_ERROR";

export const EDIT_MACHINE_MODEL = "EDIT_MACHINE_MODEL";
export const EDIT_MACHINE_MODEL_FULFILLED = "EDIT_MACHINE_MODEL_FULFILLED";
export const EDIT_MACHINE_MODEL_ERROR = "EDIT_MACHINE_MODEL_ERROR";

export const CREATE_MACHINE_MODEL = "CREATE_MACHINE_MODEL";
export const CREATE_MACHINE_MODEL_FULFILLED = "CREATE_MACHINE_MODEL_FULFILLED";
export const CREATE_MACHINE_MODEL_ERROR = "CREATE_MACHINE_MODEL_ERROR";

export const getMachineModels = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_MACHINE_MODELS });
  try {
    const { data } = await requestApi.get(
      `/machine-models?${payload?.params || ""}`
    );
    dispatch({ type: REQUEST_MACHINE_MODELS_FULFILLED, payload: data });
  } catch (err) {
    dispatch({ type: REQUEST_MACHINE_MODELS_ERROR });
  }
};

export const getMachineModelDetails = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_MACHINE_MODEL_DETAILS });
  try {
    const { data } = await requestApi.get(`/machine-models/${payload}`);
    dispatch({
      type: REQUEST_MACHINE_MODEL_DETAILS_FULFILLED,
      payload: { ...data },
    });
  } catch (err) {
    dispatch({ type: REQUEST_MACHINE_MODEL_DETAILS_ERROR });
  }
};

export const deleteMachineModel = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_MACHINE_MODEL });
  try {
    await requestApi.delete(`/machine-models/${payload}`);
    dispatch({ type: DELETE_MACHINE_MODEL_FULFILLED, payload });
  } catch (err) {
    dispatch({ type: DELETE_MACHINE_MODEL_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const editMachineModel = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_MACHINE_MODEL });
  try {
    const { data } = await requestApi.patch(
      `/machine-models/${payload._id}`,
      payload
    );
    dispatch({ type: EDIT_MACHINE_MODEL_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: EDIT_MACHINE_MODEL_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const createMachineModel = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_MACHINE_MODEL });
  try {
    const { data } = await requestApi.post(`/machine-models`, payload);
    dispatch({ type: CREATE_MACHINE_MODEL_FULFILLED, payload: { ...data } });
  } catch (err) {
    dispatch({ type: CREATE_MACHINE_MODEL_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};
