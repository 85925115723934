import { toast } from "react-toastify";
import requestApi from "utility/requestApi";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_FULFILLED = "CREATE_NOTIFICATION_FULFILLED";
export const CREATE_NOTIFICATION_ERROR = "CREATE_NOTIFICATION_ERROR";

export const createNotification = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_NOTIFICATION });
  try {
    const { data } = await requestApi.post("/notifications/region", payload);
    
    dispatch({ type: CREATE_NOTIFICATION_FULFILLED, payload: data });

    toast.success('Notification was created successfully');
  } catch (e) {
    dispatch({ type: CREATE_NOTIFICATION_ERROR });
    const { messages } = e.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(e);
  }
};
