import { toast } from "react-toastify"
import requestApi from "utility/requestApi"

export const REQUEST_DRAWINGS = 'REQUEST_DRAWINGS'
export const REQUEST_DRAWINGS_FULFILLED = 'REQUEST_DRAWINGS_FULFILLED'
export const REQUEST_DRAWINGS_ERROR = 'REQUEST_DRAWINGS_ERROR'

export const REQUEST_DRAWING_DETAILS = 'REQUEST_DRAWING_DETAILS'
export const REQUEST_DRAWING_DETAILS_FULFILLED = 'REQUEST_DRAWING_DETAILS_FULFILLED'
export const REQUEST_DRAWING_DETAILS_ERROR = 'REQUEST_DRAWING_DETAILS_ERROR'

export const DELETE_DRAWING = 'DELETE_DRAWING'
export const DELETE_DRAWING_FULFILLED = 'DELETE_DRAWING_FULFILLED'
export const DELETE_DRAWING_ERROR = 'DELETE_DRAWING_ERROR'

export const EDIT_DRAWING = 'EDIT_DRAWING'
export const EDIT_DRAWING_FULFILLED = 'EDIT_DRAWING_FULFILLED'
export const EDIT_DRAWING_ERROR = 'EDIT_DRAWING_ERROR'

export const CREATE_DRAWING = 'CREATE_DRAWING'
export const CREATE_DRAWING_FULFILLED = 'CREATE_DRAWING_FULFILLED'
export const CREATE_DRAWING_ERROR = 'CREATE_DRAWING_ERROR' 

export const getDrawings = payload => async dispatch => {
  dispatch({ type: REQUEST_DRAWINGS })
  try {
    const {data} = await requestApi.get(`/drawings${payload.params}`);
    dispatch({ type: REQUEST_DRAWINGS_FULFILLED, payload: {...data, page: payload.page} })
  } catch (err) {
    dispatch({ type: REQUEST_DRAWINGS_ERROR})
  }
}

export const getDrawingsDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_DRAWING_DETAILS })
  try {
    const {data} = await requestApi.get(`/drawings/${payload}`);
    dispatch({ type: REQUEST_DRAWING_DETAILS_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: REQUEST_DRAWING_DETAILS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const deleteDrawing = payload => async dispatch => {
  dispatch({ type: DELETE_DRAWING })
  try {
    await requestApi.delete(`/drawings/${payload}`);
    dispatch({ type: DELETE_DRAWING_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_DRAWING_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editDrawing = payload => async dispatch => {
  dispatch({ type: EDIT_DRAWING })
  try {
    const {data} = await requestApi.patch(`/drawings/${payload._id}`, payload);
    dispatch({ type: EDIT_DRAWING_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: EDIT_DRAWING_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createDrawing = payload => async dispatch => {
  dispatch({ type: CREATE_DRAWING })
  try {
    const {data} = await requestApi.post(`/drawings`, payload);
    dispatch({ type: CREATE_DRAWING_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: CREATE_DRAWING_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}