import { toast } from "react-toastify"
import requestApi from "utility/requestApi"

export const REQUEST_SURVEYS = 'REQUEST_SURVEYS'
export const REQUEST_SURVEYS_FULFILLED = 'REQUEST_SURVEYS_FULFILLED'
export const REQUEST_SURVEYS_ERROR = 'REQUEST_SURVEYS_ERROR'

export const REQUEST_SURVEY_DETAILS = 'REQUEST_SURVEY_DETAILS'
export const REQUEST_SURVEY_DETAILS_FULFILLED = 'REQUEST_SURVEY_DETAILS_FULFILLED'
export const REQUEST_SURVEY_DETAILS_ERROR = 'REQUEST_SURVEY_DETAILS_ERROR'

export const DELETE_SURVEY_RESPONSE = 'DELETE_SURVEY_RESPONSE'
export const DELETE_SURVEY_FULFILLED = 'DELETE_SURVEY_FULFILLED'
export const DELETE_SURVEY_ERROR = 'DELETE_SURVEY_ERROR'

export const EDIT_SURVEY_RESPONSE = 'EDIT_SURVEY_RESPONSE'
export const EDIT_SURVEY_FULFILLED = 'EDIT_SURVEY_FULFILLED'
export const EDIT_SURVEY_ERROR = 'EDIT_SURVEY_ERROR'

export const CREATE_SURVEY_RESPONSE = 'CREATE_SURVEY_RESPONSE'
export const CREATE_SURVEY_FULFILLED = 'CREATE_SURVEY_FULFILLED'
export const CREATE_SURVEY_ERROR = 'REQUEST_SURVEY_SURVEY_ERROR' 

export const getSurveys = payload => async dispatch => {
  dispatch({ type: REQUEST_SURVEYS })
  try {
    const {data} = await requestApi.get(`/surveys${payload.params}`);
    dispatch({ type: REQUEST_SURVEYS_FULFILLED, payload: {...data, page: payload.page} })
  } catch (err) {
    dispatch({ type: REQUEST_SURVEYS_ERROR})
  }
}

export const getSurveyDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_SURVEY_DETAILS })
  try {
    const {data} = await requestApi.get(`/surveys/${payload}`);
    dispatch({ type: REQUEST_SURVEY_DETAILS_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: REQUEST_SURVEY_DETAILS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const deleteSurvey = payload => async dispatch => {
  dispatch({ type: DELETE_SURVEY_RESPONSE })
  try {
    await requestApi.delete(`/surveys/${payload}`);
    dispatch({ type: DELETE_SURVEY_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_SURVEY_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editSurvey = payload => async dispatch => {
  dispatch({ type: EDIT_SURVEY_RESPONSE })
  try {
    const {data} = await requestApi.patch(`/surveys/${payload._id}`, payload);
    dispatch({ type: EDIT_SURVEY_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: EDIT_SURVEY_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createSurvey = payload => async dispatch => {
  dispatch({ type: CREATE_SURVEY_RESPONSE })
  try {
    const {data} = await requestApi.post(`/surveys`, payload);
    dispatch({ type: CREATE_SURVEY_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: CREATE_SURVEY_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}