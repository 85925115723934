import { toast } from "react-toastify";
import requestApi, { refreshTokenFailCallback } from "utility/requestApi";
import cookies from "utility/cookieHandler";
import {
  LOGIN_START,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FETCH_ME_START,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "redux/actionTypes";

export const login = (data) => async (dispatch) => {
  dispatch({ type: LOGIN_START, payload: data });
  try {
    const response = await requestApi.post(`/users/login`, data);
    cookies.setCookie("token", response.data.token);
    cookies.setCookie("userId", response.data._id);
    await dispatch(fetchMe());
    return dispatch({ type: LOGIN_SUCCESS, user: response.data });
  } catch (err) {
    cookies.deleteCookie("token");
    cookies.deleteCookie("userId");
    toast.error("Error: Email or Password is incorrect.");
    dispatch({ type: LOGIN_FAILURE, err });
  }
};

const cookiesNames = ["token", "refreshToken", "userId", "partnerId"];
export const logout = (data) => async (dispatch) => {
  dispatch({ type: LOGOUT_START, payload: data });
  try {
    cookiesNames.forEach((name) => cookies.deleteCookie(name));
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (err) {
    dispatch({ type: LOGOUT_FAILURE, err });
    return Promise.reject(err.response.data);
  }
};

export const fetchMe = () => async (dispatch) => {
  if (refreshTokenFailCallback.length === 0) {
    refreshTokenFailCallback.push(() => dispatch(logout()));
  }
  dispatch({ type: FETCH_ME_START });
  try {
    const userId = cookies.getCookie("userId");
    if (userId) {
      const { data: user } = await requestApi.get(`/users/currentUser`);
      const permissions = await getUserRoles(user.acl);
      dispatch({ type: FETCH_ME_SUCCESS, user, permissions });
    } else {
      throw new Error("User not logged in");
    }
  } catch (err) {
    dispatch({ type: FETCH_ME_FAILURE, err });
    return Promise.reject(err.response.data);
  }
};

async function getUserRoles(acl) {
  let promises = [];
  if (!acl.length) {
    return promises;
  }

  acl.forEach((item) => {
    promises.push(
      requestApi
        .get(`/permissions/?role=${item.role}`)
        .then(({ data }) => data.docs)
    );
  });
  const responses = await Promise.all(promises);
  return responses
    .flat()
    .map((item) =>
      Object.keys(item.read).map((permission) =>
        item.read[permission] ? permission : null
      )
    )
    .flat()
    .filter(Boolean);
}

export const changePassword = () => async (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD_START });
  try {
    // const { data: user } = await requestApi.post(`/api/ChangePassword`);
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (err) {
    dispatch({ type: CHANGE_PASSWORD_FAILURE, err });
    return Promise.reject(err.response.data);
  }
};
