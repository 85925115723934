import {
  CREATE_GAME,
  CREATE_GAME_ERROR,
  CREATE_GAME_FULFILLED,
  DELETE_GAME,
  DELETE_GAME_ERROR,
  DELETE_GAME_FULFILLED,
  EDIT_GAME,
  EDIT_GAME_ERROR,
  EDIT_GAME_FULFILLED,
  REQUEST_GAMES,
  REQUEST_GAMES_ERROR,
  REQUEST_GAMES_FULFILLED,
  REQUEST_GAME_DETAILS,
  REQUEST_GAME_DETAILS_ERROR,
  REQUEST_GAME_DETAILS_FULFILLED,
} from "redux/actions/games";

const initialState = {
  list: [],
  loading: true,
  page: 1,
  editSuccess: false,
  createSuccess: false,
  loadingCreate: false,
  deleteSuccess: false,
  totalPages: 1,
};

const games = (state = initialState, { type, payload }) => {
  switch (type) {
    // REQUEST
    case REQUEST_GAMES:
      return { ...state, loading: true };
    case REQUEST_GAME_DETAILS:
      return { ...state };
    case DELETE_GAME:
      return { ...state, editSuccess: true };
    case EDIT_GAME:
      return { ...state, deleteSuccess: true };
    case CREATE_GAME:
      return { ...state, createSuccess: true, loadingCreate: true };
    // fullfilled
    case REQUEST_GAMES_FULFILLED:
      return {
        ...state,
        page: payload.page,
        list: payload.docs,
        totalPages: payload.pages,
        loading: false,
      };
    case REQUEST_GAME_DETAILS_FULFILLED:
      return { ...state };
    case DELETE_GAME_FULFILLED:
      return { ...state, deleteSuccess: false };
    case EDIT_GAME_FULFILLED:
      return { ...state, editSuccess: false };
    case CREATE_GAME_FULFILLED:
      return { ...state, createSuccess: false, loadingCreate: false };
    // error
    case REQUEST_GAMES_ERROR:
      return { ...state };
    case REQUEST_GAME_DETAILS_ERROR:
      return { ...state };
    case DELETE_GAME_ERROR:
      return { ...state, deleteSuccess: false };
    case EDIT_GAME_ERROR:
      return { ...state, editSuccess: false };
    case CREATE_GAME_ERROR:
      return { ...state, createSuccess: false, loadingCreate: false };
    default:
      return state;
  }
};

export default games;
