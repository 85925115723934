import { toast } from "react-toastify"
import requestApi from "utility/requestApi"

export const REQUEST_CONFIGURATIONS = 'REQUEST_CONFIGURATIONS'
export const REQUEST_CONFIGURATIONS_FULFILLED = 'REQUEST_CONFIGURATIONS_FULFILLED'
export const REQUEST_CONFIGURATIONS_ERROR = 'REQUEST_CONFIGURATIONS_ERROR'

export const REQUEST_CONFIGURATION_DETAILS = 'REQUEST_CONFIGURATION_DETAILS'
export const REQUEST_CONFIGURATION_DETAILS_FULFILLED = 'REQUEST_CONFIGURATION_DETAILS_FULFILLED'
export const REQUEST_CONFIGURATION_DETAILS_ERROR = 'REQUEST_CONFIGURATION_DETAILS_ERROR'

export const DELETE_CONFIGURATION = 'DELETE_CONFIGURATION'
export const DELETE_CONFIGURATION_FULFILLED = 'DELETE_CONFIGURATION_FULFILLED'
export const DELETE_CONFIGURATION_ERROR = 'DELETE_CONFIGURATION_ERROR'

export const EDIT_CONFIGURATION = 'EDIT_CONFIGURATION'
export const EDIT_CONFIGURATION_FULFILLED = 'EDIT_CONFIGURATION_FULFILLED'
export const EDIT_CONFIGURATION_ERROR = 'EDIT_CONFIGURATION_ERROR'

export const CREATE_CONFIGURATION = 'CREATE_CONFIGURATION'
export const CREATE_CONFIGURATION_FULFILLED = 'CREATE_CONFIGURATION_FULFILLED'
export const CREATE_CONFIGURATION_ERROR = 'CREATE_CONFIGURATION_ERROR' 

export const getConfigurations = payload => async dispatch => {
  dispatch({ type: REQUEST_CONFIGURATIONS })
  try {
    const {data} = await requestApi.get(`/global${payload.params}`);
    dispatch({ type: REQUEST_CONFIGURATIONS_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: REQUEST_CONFIGURATIONS_ERROR})
  }
}

export const getConfigurationDetails = payload => async dispatch => {
  dispatch({ type: REQUEST_CONFIGURATION_DETAILS })
  try {
    const {data} = await requestApi.get(`/global/${payload}`);
    dispatch({ type: REQUEST_CONFIGURATION_DETAILS_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: REQUEST_CONFIGURATION_DETAILS_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const deleteConfiguration = payload => async dispatch => {
  dispatch({ type: DELETE_CONFIGURATION })
  try {
    await requestApi.delete(`/global/${payload}`);
    dispatch({ type: DELETE_CONFIGURATION_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_CONFIGURATION_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const editConfiguration = payload => async dispatch => {
  dispatch({ type: EDIT_CONFIGURATION })
  try {
    const {data} = await requestApi.patch(`/global/${payload._id}`, payload);
    dispatch({ type: EDIT_CONFIGURATION_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: EDIT_CONFIGURATION_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}

export const createConfiguration = payload => async dispatch => {
  dispatch({ type: CREATE_CONFIGURATION })
  try {
    const {data} = await requestApi.post(`/global`, payload);
    dispatch({ type: CREATE_CONFIGURATION_FULFILLED, payload: {...data} })
  } catch (err) {
    dispatch({ type: CREATE_CONFIGURATION_ERROR})
    const {messages} = err.response.data.error
    messages && toast.error(messages[0])
    return Promise.reject(err)
  }
}