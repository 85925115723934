import { CREATE_DRAWING, CREATE_DRAWING_ERROR, CREATE_DRAWING_FULFILLED, DELETE_DRAWING, DELETE_DRAWING_ERROR, DELETE_DRAWING_FULFILLED, EDIT_DRAWING, EDIT_DRAWING_ERROR, EDIT_DRAWING_FULFILLED, REQUEST_DRAWINGS, REQUEST_DRAWINGS_ERROR, REQUEST_DRAWINGS_FULFILLED, REQUEST_DRAWING_DETAILS, REQUEST_DRAWING_DETAILS_ERROR, REQUEST_DRAWING_DETAILS_FULFILLED } from "redux/actions/drawings"

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1
}

const drawings = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_DRAWINGS:
      return {...state, }
    case REQUEST_DRAWING_DETAILS:
      return {...state, }
    case DELETE_DRAWING:
      return {...state, }
    case EDIT_DRAWING:
      return {...state, }
    case CREATE_DRAWING:
      return {...state, }
    // fullfilled
    case REQUEST_DRAWINGS_FULFILLED:
      return { ...state, page: payload.page, list: payload.drawings, totalPages: payload.pages }
    case REQUEST_DRAWING_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_DRAWING_FULFILLED:
      return {...state, }
    case EDIT_DRAWING_FULFILLED:
      return {...state, }
    case CREATE_DRAWING_FULFILLED:
      return {...state, }
      // error
    case REQUEST_DRAWINGS_ERROR:
      return {...state, }
    case REQUEST_DRAWING_DETAILS_ERROR:
      return {...state, }
    case DELETE_DRAWING_ERROR:
      return {...state, }
    case EDIT_DRAWING_ERROR:
      return {...state, }
    case CREATE_DRAWING_ERROR:
      return {...state, }
    default:
      return state
  }
}

export default drawings