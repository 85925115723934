import { toast } from "react-toastify";
import requestApi from "utility/requestApi";

export const REQUEST_MACHINE_MANUFACTURERS = "REQUEST_MACHINE_MANUFACTURERS";
export const REQUEST_MACHINE_MANUFACTURERS_FULFILLED =
  "REQUEST_MACHINE_MANUFACTURERS_FULFILLED";
export const REQUEST_MACHINE_MANUFACTURERS_ERROR =
  "REQUEST_MACHINE_MANUFACTURERS_ERROR";

export const REQUEST_MACHINE_MANUFACTURER_DETAILS =
  "REQUEST_MACHINE_MANUFACTURER_DETAILS";
export const REQUEST_MACHINE_MANUFACTURER_DETAILS_FULFILLED =
  "REQUEST_MACHINE_MANUFACTURER_DETAILS_FULFILLED";
export const REQUEST_MACHINE_MANUFACTURER_DETAILS_ERROR =
  "REQUEST_MACHINE_MANUFACTURER_DETAILS_ERROR";

export const DELETE_MACHINE_MANUFACTURER = "DELETE_MACHINE_MANUFACTURER";
export const DELETE_MACHINE_MANUFACTURER_FULFILLED =
  "DELETE_MACHINE_MANUFACTURER_FULFILLED";
export const DELETE_MACHINE_MANUFACTURER_ERROR =
  "DELETE_MACHINE_MANUFACTURER_ERROR";

export const EDIT_MACHINE_MANUFACTURER = "EDIT_MACHINE_MANUFACTURER";
export const EDIT_MACHINE_MANUFACTURER_FULFILLED =
  "EDIT_MACHINE_MANUFACTURER_FULFILLED";
export const EDIT_MACHINE_MANUFACTURER_ERROR =
  "EDIT_MACHINE_MANUFACTURER_ERROR";

export const CREATE_MACHINE_MANUFACTURER = "CREATE_MACHINE_MANUFACTURER";
export const CREATE_MACHINE_MANUFACTURER_FULFILLED =
  "CREATE_MACHINE_MANUFACTURER_FULFILLED";
export const CREATE_MACHINE_MANUFACTURER_ERROR =
  "CREATE_MACHINE_MANUFACTURER_ERROR";

export const getMachineManufacturers = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_MACHINE_MANUFACTURERS });
  try {
    const { data } = await requestApi.get(
      `/machine-manufacturers${payload?.params || ""}`
    );
    dispatch({ type: REQUEST_MACHINE_MANUFACTURERS_FULFILLED, payload: data });
  } catch (err) {
    dispatch({ type: REQUEST_MACHINE_MANUFACTURERS_ERROR, err });
  }
};

export const getMachineManufacturerDetails = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_MACHINE_MANUFACTURER_DETAILS });
  try {
    const { data } = await requestApi.get(`/machine-manufacturers/${payload}`);
    dispatch({
      type: REQUEST_MACHINE_MANUFACTURER_DETAILS_FULFILLED,
      payload: { ...data },
    });
  } catch (err) {
    dispatch({ type: REQUEST_MACHINE_MANUFACTURER_DETAILS_ERROR });
  }
};

export const deleteMachineManufacturer = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_MACHINE_MANUFACTURER });
  try {
    await requestApi.delete(`/machine-manufacturers/${payload}`);
    dispatch({ type: DELETE_MACHINE_MANUFACTURER_FULFILLED, payload });
  } catch (err) {
    dispatch({ type: DELETE_MACHINE_MANUFACTURER_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const editMachineManufacturer = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_MACHINE_MANUFACTURER });
  try {
    const { data } = await requestApi.patch(
      `/machine-manufacturers/${payload._id}`,
      payload
    );
    dispatch({
      type: EDIT_MACHINE_MANUFACTURER_FULFILLED,
      payload: { ...data },
    });
  } catch (err) {
    dispatch({ type: EDIT_MACHINE_MANUFACTURER_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};

export const createMachineManufacturer = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_MACHINE_MANUFACTURER });
  try {
    const { data } = await requestApi.post(`/machine-manufacturers`, payload);
    dispatch({
      type: CREATE_MACHINE_MANUFACTURER_FULFILLED,
      payload: { ...data },
    });
  } catch (err) {
    dispatch({ type: CREATE_MACHINE_MANUFACTURER_ERROR });
    const { messages } = err.response.data.error;
    messages && toast.error(messages[0]);
    return Promise.reject(err);
  }
};
