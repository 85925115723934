import { toast } from 'react-toastify'
import requestApi from 'utility/requestApi'
import { serialize } from 'object-to-formdata'
import queryString from 'query-string'
import {
  GET_PROMOTION_DASHBOARD_FAILURE,
  GET_PROMOTION_DASHBOARD_START,
  GET_PROMOTION_DASHBOARD_SUCCESS
} from 'redux/actionTypes'

export const GET_PROMOTIONS = 'GET_PROMOTIONS'
export const GET_PROMOTIONS_FULFILLED = 'GET_PROMOTIONS_FULFILLED'
export const GET_PROMOTIONS_ERROR = 'GET_PROMOTIONS_ERROR'

export const CREATE_PROMOTION = 'CREATE_PROMOTION'
export const CREATE_PROMOTION_FULFILLED = 'CREATE_PROMOTION_FULFILLED'
export const CREATE_PROMOTION_ERROR = 'CREATE_PROMOTION_ERROR'

export const EDIT_PROMOTION = 'EDIT_PROMOTION'
export const EDIT_PROMOTION_FULFILLED = 'EDIT_PROMOTION_FULFILLED'
export const EDIT_PROMOTION_ERROR = 'EDIT_PROMOTION_ERROR'

export const DELETE_PROMOTION = 'DELETE_PROMOTION'
export const DELETE_PROMOTION_FULFILLED = 'DELETE_PROMOTION_FULFILLED'
export const DELETE_PROMOTION_ERROR = 'DELETE_PROMOTION_ERROR'

export const GET_PROMOTION = 'GET_PROMOTION'
export const GET_PROMOTION_FULFILLED = 'GET_PROMOTION_FULFILLED'
export const GET_PROMOTION_ERROR = 'GET_PROMOTION_ERROR'

export const GET_PROMOTION_DRAWINGS = 'GET_PROMOTION_DRAWINGS'
export const GET_PROMOTION_DRAWINGS_FULFILLED =
  'GET_PROMOTION_DRAWINGS_FULFILLED'
export const GET_PROMOTION_DRAWINGS_ERROR = 'GET_PROMOTION_DRAWINGS_ERROR'

export const EDIT_PROMOTION_DRAWINGS = 'EDIT_PROMOTION_DRAWINGS'
export const EDIT_PROMOTION_DRAWINGS_FULFILLED =
  'EDIT_PROMOTION_DRAWING_FULFILLED'
export const EDIT_PROMOTION_DRAWINGS_ERROR = 'EDIT_PROMOTION_DRAWING_ERROR'

export const CREATE_PROMOTION_DRAWINGS = 'CREATE_PROMOTION_DRAWINGS'
export const CREATE_PROMOTION_DRAWINGS_FULFILLED =
  'CREATE_PROMOTION_DRAWING_FULFILLED'
export const CREATE_PROMOTION_DRAWINGS_ERROR = 'CREATE_PROMOTION_DRAWING_ERROR'

export const GET_PROMOTION_GROUP = 'GET_PROMOTION_GROUP'
export const GET_PROMOTION_GROUP_FULFILLED = 'GET_PROMOTION_GROUP_FULFILLED'
export const GET_PROMOTION_GROUP_ERROR = 'GET_PROMOTION_GROUP_ERROR'

export const CREATE_PROMOTION_GROUP = 'CREATE_PROMOTION_GROUP'
export const CREATE_PROMOTION_GROUP_FULFILLED =
  'CREATE_PROMOTION_GROUP_FULFILLED'
export const CREATE_PROMOTION_GROUP_ERROR = 'CREATE_PROMOTION_GROUP_ERROR'

export const EDIT_PROMOTION_GROUP = 'EDIT_PROMOTION_GROUP'
export const EDIT_PROMOTION_GROUP_FULFILLED = 'EDIT_PROMOTION_GROUP_FULFILLED'
export const EDIT_PROMOTION_GROUP_ERROR = 'EDIT_PROMOTION_GROUP_ERROR'

export const GET_PROMOTION_ENTRIES = 'GET_PROMOTION_ENTRIES'
export const GET_PROMOTION_ENTRIES_FULFILLED = 'GET_PROMOTION_ENTRIES_FULFILLED'
export const GET_PROMOTION_ENTRIES_ERROR = 'GET_PROMOTION_ENTRIES_ERROR'

export const DRAW_PROMOTION_WINNERS = 'DRAW_PROMOTION_WINNERS'
export const DRAW_PROMOTION_WINNERS_FULFILLED =
  'DRAW_PROMOTION_WINNERS_FULFILLED'
export const DRAW_PROMOTION_WINNERS_ERROR = 'DRAW_PROMOTION_WINNERS_ERROR'

export const GET_PROMOTION_WINNERS = 'GET_PROMOTION_WINNERS'
export const GET_PROMOTION_WINNERS_FULFILLED = 'GET_PROMOTION_WINNERS_FULFILLED'
export const GET_PROMOTION_WINNERS_ERROR = 'GET_PROMOTION_WINNERS_ERROR'

export const REDRAW_PROMOTION_WINNER = 'REDRAW_PROMOTION_WINNER'
export const REDRAW_PROMOTION_WINNER_FULFILLED =
  'REDRAW_PROMOTION_WINNER_FULFILLED'
export const REDRAW_PROMOTION_WINNER_ERROR = 'REDRAW_PROMOTION_WINNER_ERROR'

export const CONFIRM_PROMOTION_WINNER = 'CONFIRM_PROMOTION_WINNER'
export const CONFIRM_PROMOTION_WINNER_FULFILLED =
  'CONFIRM_PROMOTION_WINNER_FULFILLED'
export const CONFIRM_PROMOTION_WINNER_ERROR = 'CONFIRM_PROMOTION_WINNER_ERROR'

export const CONFIRM_ALL_PROMOTION_WINNER = 'CONFIRM_ALL_PROMOTION_WINNER'
export const CONFIRM_ALL_PROMOTION_WINNER_FULFILLED =
  'CONFIRM_ALL_PROMOTION_WINNER_FULFILLED'
export const CONFIRM_ALL_PROMOTION_WINNER_ERROR =
  'CONFIRM_ALL_PROMOTION_WINNER_ERROR'

export const CREATE_PROMOTION_ENTRY = 'CREATE_PROMOTION_ENTRY'
export const CREATE_PROMOTION_ENTRY_FULFILLED =
  'CREATE_PROMOTION_ENTRY_FULFILLED'
export const CREATE_PROMOTION_ENTRY_ERROR = 'CREATE_PROMOTION_ENTRY_ERROR'

export const getPromotions = (payload) => async (dispatch) => {
  dispatch({ type: GET_PROMOTIONS })
  try {
    const { data } = await requestApi.get(`/promotions${payload.params}`)
    dispatch({
      type: GET_PROMOTIONS_FULFILLED,
      payload: {
        ...data,
        docs: data.docs.map((i) => ({
          ...i,
          startDate: i.drawings[0]?.startDate || '',
          endDate: i.drawings[0]?.endDate || '',
          allowEntriesSameLocationRetrySeconds:
            Number((i.drawings[0]?.allowEntriesSameLocationRetrySeconds || 3600) / 60) ||
            0,
          allowEntriesWithDifferentLocation:
            i.drawings[0]?.allowEntriesWithDifferentLocation || false
        })),
        page: payload.page
      }
    })
  } catch (err) {
    console.log(err)
    dispatch({ type: GET_PROMOTIONS_ERROR })
  }
}

export const createPromotion = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_PROMOTION })
  try {
    const { data } = await requestApi.post(`/promotions`, serialize(payload))
    dispatch({ type: CREATE_PROMOTION_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: CREATE_PROMOTION_ERROR })
    const { messages } = err.response.data.error
    if (messages) {
      toast.error(messages[0])
    }
    return Promise.reject(err)
  }
}

export const editPromotion = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_PROMOTION })
  try {
    const {
      backgroundImage,
      foregroundImage,
      subsequentEntryBackgroundImage,
      firstVisitBackgroundImage,
      dottysBackgroundImage,
      shelbysBackgroundImage,
      stellasBackgroundImage,
      rewardPrizeImage,
      logo,
      ...rest
    } = payload

    const { data } = await requestApi.patch(
      `/promotions/${payload._id}`,
      serialize({
        backgroundImage,
        foregroundImage,
        subsequentEntryBackgroundImage,
        firstVisitBackgroundImage,
        dottysBackgroundImage,
        shelbysBackgroundImage,
        stellasBackgroundImage,
        rewardPrizeImage,
        logo
      })
    )
    const response = await requestApi.patch(`/promotions/${payload._id}`, rest)

    dispatch({
      type: EDIT_PROMOTION_FULFILLED,
      payload: { ...data, ...response.data }
    })
  } catch (err) {
    dispatch({ type: EDIT_PROMOTION_ERROR })
    const { messages } = err.response.data.error
    if (messages) {
      toast.error(messages[0])
    }
    return Promise.reject(err)
  }
}

export const deletePromotion = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_PROMOTION })
  try {
    await requestApi.delete(`/promotions/${payload}`)
    dispatch({ type: DELETE_PROMOTION_FULFILLED, payload })
  } catch (err) {
    dispatch({ type: DELETE_PROMOTION_ERROR })
    const { messages } = err.response.data.error
    if (messages) {
      toast.error(messages[0])
    }
    return Promise.reject(err)
  }
}

export const getPromotion = (payload) => async (dispatch) => {
  dispatch({ type: GET_PROMOTION })
  try {
    const { data } = await requestApi.get(`/promotions/${payload}`)
    const { drawings, startDate, endDate } = data
    if (drawings) {
      data.drawings = drawings.map(
        (drawing) => drawing.drawingDate?.split('T')?.[0]
      )
    }
    if (startDate) {
      data.startDate = data.startDate?.split('T')?.[0]
    }
    if (endDate) {
      data.endDate = data.endDate?.split('T')?.[0]
    }
    dispatch({ type: GET_PROMOTION_FULFILLED, payload: data })
    return data
  } catch (err) {
    dispatch({ type: GET_PROMOTION_ERROR })
  }
}

export const getPromotionDrawings = (promotion) => async (dispatch) => {
  dispatch({ type: GET_PROMOTION })
  try {
    const { data } = await requestApi.get(`/promotions/${promotion}/drawings`)

    dispatch({ type: GET_PROMOTION_DRAWINGS_FULFILLED, payload: data })
  } catch (error) {
    dispatch({ type: GET_PROMOTION_DRAWINGS_ERROR })
  }
}

export const createPromotionDrawing = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_PROMOTION_DRAWINGS })
  try {
    const { data } = await requestApi.post(
      `/promotions/${payload.promotion}/drawings`,
      payload
    )

    dispatch(getPromotionDrawings(payload.promotion))
    dispatch({ type: CREATE_PROMOTION_DRAWINGS_FULFILLED, payload: data })
  } catch (error) {
    dispatch({ type: CREATE_PROMOTION_DRAWINGS_ERROR })
  }
}

export const editPromotionDrawing = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_PROMOTION_DRAWINGS })
  try {
    const { data } = await requestApi.patch(
      `/promotions/${payload.promotion}/drawings/${payload.id}`,
      payload
    )

    dispatch(getPromotionDrawings(payload.promotion))
    dispatch({ type: EDIT_PROMOTION_DRAWINGS_FULFILLED, payload: data })
  } catch (error) {
    dispatch({ type: EDIT_PROMOTION_DRAWINGS_ERROR })
  }
}

export const getPromotionGroup = (promotion) => async (dispatch) => {
  dispatch({ type: GET_PROMOTION_GROUP })
  try {
    const { data } = await requestApi.get(`/promotions/${promotion}/groups`)

    dispatch({ type: GET_PROMOTION_GROUP_FULFILLED, payload: data })
  } catch (error) {
    dispatch({ type: GET_PROMOTION_GROUP_ERROR })
  }
}

export const createPromotionGroup = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_PROMOTION_GROUP })
  try {
    const { data } = await requestApi.post(
      `/promotions/${payload.promotion}/groups`,
      payload
    )

    dispatch(getPromotionGroup(payload.promotion))
    dispatch({ type: CREATE_PROMOTION_GROUP_FULFILLED, payload: data })
  } catch (error) {
    dispatch({ type: CREATE_PROMOTION_GROUP_ERROR })
  }
}

export const editPromotionGroup = (payload) => async (dispatch) => {
  dispatch({ type: EDIT_PROMOTION_GROUP })
  try {
    const { data } = await requestApi.patch(
      `/promotions/${payload.promotion}/groups/${payload._id}`,
      payload
    )

    dispatch(getPromotionGroup(payload.promotion))
    dispatch({ type: EDIT_PROMOTION_GROUP_FULFILLED, payload: data })
  } catch (error) {
    dispatch({ type: EDIT_PROMOTION_GROUP_ERROR })
  }
}

export const getPromotionEntries = (promotion) => async (dispatch) => {
  dispatch({ type: GET_PROMOTION_ENTRIES })
  try {
    const url = `/promotions/${promotion.id}/entries?${queryString.stringify(
      promotion.params
    )}`
    // promotion.params ? `?maxDate=${promotion.params.maxDate}&minDate=${promotion.params.minDate}` : ""
    const { data } = await requestApi.get(url)

    dispatch({ type: GET_PROMOTION_ENTRIES_FULFILLED, payload: data })
  } catch (error) {
    dispatch({ type: GET_PROMOTION_ENTRIES_ERROR })
  }
}

export const drawPromotionWinner =
  ({ drawingId }) =>
  async (dispatch) => {
    dispatch({ type: DRAW_PROMOTION_WINNERS, payload: { drawingId } })
    try {
      const { data } = await requestApi.post(
        `/promotion-drawings/${drawingId}/winners`
      )

      dispatch({ type: DRAW_PROMOTION_WINNERS_FULFILLED, payload: data })
    } catch (error) {
      dispatch({ type: DRAW_PROMOTION_WINNERS_ERROR })
    }
  }

export const getDrawingWinners =
  ({ drawingId }) =>
  async (dispatch) => {
    dispatch({ type: GET_PROMOTION_WINNERS, payload: { drawingId } })
    try {
      const { data } = await requestApi.get(
        `/promotion-drawings/${drawingId}/winners?limit=1000`
      )

      dispatch({
        type: GET_PROMOTION_WINNERS_FULFILLED,
        payload: { winners: data.docs, drawingId }
      })
    } catch (error) {
      dispatch({ type: GET_PROMOTION_WINNERS_ERROR })
    }
  }

export const redrawDrawingWinners =
  ({ drawingId, winnerId }) =>
  async (dispatch) => {
    dispatch({
      type: REDRAW_PROMOTION_WINNER,
      payload: { drawingId, winnerId }
    })
    try {
      const { data } = await requestApi.patch(
        `/promotion-drawings/${drawingId}/winners/${winnerId}`
      )

      dispatch({ type: REDRAW_PROMOTION_WINNER_FULFILLED, payload: data })
    } catch (error) {
      dispatch({ type: REDRAW_PROMOTION_WINNER_ERROR })
    }
  }

export const confirmDrawingWinners =
  ({ drawingId, winnerId }) =>
  async (dispatch) => {
    dispatch({
      type: CONFIRM_PROMOTION_WINNER,
      payload: { drawingId, winnerId }
    })
    try {
      const { data } = await requestApi.post(
        `/promotion-drawings/${drawingId}/winners/${winnerId}/confirm`
      )

      dispatch({ type: CONFIRM_PROMOTION_WINNER_FULFILLED, payload: data })
    } catch (error) {
      dispatch({ type: CONFIRM_PROMOTION_WINNER_ERROR })
    }
  }

export const confirmAllDrawingWinners =
  ({ drawingId }) =>
  async (dispatch) => {
    dispatch({
      type: CONFIRM_ALL_PROMOTION_WINNER,
      payload: { drawingId }
    })
    try {
      const { data } = await requestApi.post(
        `/promotion-drawings/${drawingId}/winners/confirm`
      )

      dispatch({ type: CONFIRM_ALL_PROMOTION_WINNER_FULFILLED, payload: data })
    } catch (error) {
      dispatch({ type: CONFIRM_ALL_PROMOTION_WINNER_ERROR })
    }
  }

export const createPromotionEntry =
  ({ promotion, payload }) =>
  async (dispatch) => {
    dispatch({
      type: CREATE_PROMOTION_ENTRY,
      payload: { promotion, payload }
    })
    try {
      const { data } = await requestApi.post(
        `/promotions/${promotion}/join`,
        payload
      )

      dispatch({ type: CREATE_PROMOTION_ENTRY_FULFILLED, payload: data })
    } catch (error) {
      dispatch({ type: CREATE_PROMOTION_ENTRY_ERROR })
      throw error
    }
  }

export const getPromotionDashboard = (payload) => async (dispatch) => {
  dispatch({ type: GET_PROMOTION_DASHBOARD_START })
  try {
    const { data } = await requestApi.get(
      `/promotions/${payload.id}/activity?${payload.params}`
    )
    dispatch({
      type: GET_PROMOTION_DASHBOARD_SUCCESS,
      payload: data
    })
  } catch (err) {
    dispatch({ type: GET_PROMOTION_DASHBOARD_FAILURE, err })
  }
}
