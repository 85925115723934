import requestApi from "utility/requestApi";

export const GET_PROMOTION_DEVICES = "GET_PROMOTION_DEVICES";
export const GET_PROMOTION_DEVICES_FULFILLED = "GET_PROMOTION_DEVICES_FULFILLED";
export const GET_PROMOTION_DEVICES_ERROR = "GET_PROMOTION_DEVICES_ERROR";


export const getPromotionDevices = (payload) => async (dispatch) => {
  dispatch({ type: GET_PROMOTION_DEVICES });
  try {
    const params = `?${new URLSearchParams(payload.params)}`;
    const { data } = await requestApi.get(`/promotion-devices${params}`);
    dispatch({
      type: GET_PROMOTION_DEVICES_FULFILLED,
      payload: { ...data, page: payload.page },
    });
  } catch (err) {
    dispatch({ type: GET_PROMOTION_DEVICES_ERROR, err });
  }
};

