import { CREATE_PRODUCT, CREATE_PRODUCT_ERROR, CREATE_PRODUCT_FULFILLED, DELETE_PRODUCT, DELETE_PRODUCT_ERROR, DELETE_PRODUCT_FULFILLED, EDIT_PRODUCT, EDIT_PRODUCT_ERROR, EDIT_PRODUCT_FULFILLED, REQUEST_PRODUCTS, REQUEST_PRODUCTS_ERROR, REQUEST_PRODUCTS_FULFILLED, REQUEST_PRODUCT_DETAILS, REQUEST_PRODUCT_DETAILS_ERROR, REQUEST_PRODUCT_DETAILS_FULFILLED } from "redux/actions/products"

const initialState = {
  list: [],
  surveys: {},
  loading: true,
  page: 1,
  totalPages: 1
}

const products = (state = initialState, {type, payload}) => {
  switch (type) {
    // REQUEST
    case REQUEST_PRODUCTS:
      return {...state, }
    case REQUEST_PRODUCT_DETAILS:
      return {...state, }
    case DELETE_PRODUCT:
      return {...state, }
    case EDIT_PRODUCT:
      return {...state, }
    case CREATE_PRODUCT:
      return {...state, }
    // fullfilled
    case REQUEST_PRODUCTS_FULFILLED:
      return { ...state, page: payload.page, list: payload.products, totalPages: payload.pages }
    case REQUEST_PRODUCT_DETAILS_FULFILLED:
      return {...state, }
    case DELETE_PRODUCT_FULFILLED:
      return {...state, }
    case EDIT_PRODUCT_FULFILLED:
      return {...state, }
    case CREATE_PRODUCT_FULFILLED:
      return {...state, }
      // error
    case REQUEST_PRODUCTS_ERROR:
      return {...state, }
    case REQUEST_PRODUCT_DETAILS_ERROR:
      return {...state, }
    case DELETE_PRODUCT_ERROR:
      return {...state, }
    case EDIT_PRODUCT_ERROR:
      return {...state, }
    case CREATE_PRODUCT_ERROR:
      return {...state, }
    default:
      return state
  }
}

export default products
